//import { createSelector } from 'reselect'
import dissoc from 'ramda/es/dissoc'

const initialState = {}

const UPDATE_FILTER = 'UPDATE_FILTER'

export default (state = initialState, action = {}) => {
  const { type, payload = {} } = action

  switch (type) {
    case UPDATE_FILTER:
      return payload.val
        ? {
            ...state,
            [payload.name]: payload.val,
          }
        : dissoc(payload.name, state)
    default:
      return state
  }
}

export const updateFilter = (name, val) => ({
  type: UPDATE_FILTER,
  payload: { name, val },
})
