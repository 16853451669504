import $ from 'jquery'
import lottie from 'lottie-web'
import app from '../init/react/Bootstrapper'
import * as animationData from '../shared/animations/cloudAnim.json'
import Stats from '../util/stats'
import Cookies from 'universal-cookie'

const cookies = new Cookies()

import {
  ACTIVATE_DARKMODE,
  DEACTIVATE_DARKMODE,
} from '../redux/modules/darkmode'

export default {
  init() {
    /* eslint-disable */
    console.log(
      '%c © made with ♥ by 360VIER GmbH ',
      'color: #fff; background-color: #58b6a4;'
    )
    /* eslint-enable */

    init_breakpoints()
    init_ie()
    init_isTouchDevice()
    init_setFPS()
    init_responsiveTables()
    init_brightness()
    init_heroclouds()
    init_sitemap()
    init_movedown()
    init_smooth_scrolling_anchors()
    init_vcTtaAccordion()
    init_vcTtaTabs()

    $(window).resize(init_breakpoints)

    $(document).ready(function () {
      $('body').addClass('loaded')
    })

    function init_locationHashChanged() {
      switch (location.hash) {
        case '#co2abdruck':
          window.history.back()
          $('.co2-modal').addClass('open')
          break
        case '#share':
          window.history.back()
          setTimeout(function () {
            const shareModal = $('.share-modal')
            const shareCookie = {
              link: cookies.get('shareLink'),
              title: cookies.get('shareTitle'),
            }
            let headline = shareModal.find('.headline strong').text()
            headline = headline.replace('[[TITLE]]', shareCookie.title)
            shareModal.find('.headline strong').html(headline)
            shareModal.find('[href]').each(function () {
              let href = $(this).attr('href')
              href = href.replace('{{LINK}}', shareCookie.link)
              href = href.replace('{{TITLE}}', shareCookie.title)
              $(this).attr('href', href)
            })
            shareModal.addClass('open')
          }, 250)
          break
        default:
          break
      }
    }

    $(window).load(init_locationHashChanged)
    window.onhashchange = init_locationHashChanged

    $('.dhsv_modal').each(function () {
      const modal = $(this)

      modal.find('.overlay').click(function () {
        window.location.href.split('#')[0]
        modal.removeClass('open')
        const scrollTop = $(window).scrollTop()
        $('body, html').animate(
          {
            scrollTop: scrollTop,
          },
          0
        )
      })

      modal.find('.mobileclose').click(function () {
        window.location.href.split('#')[0]
        modal.removeClass('open')
        const scrollTop = $(window).scrollTop()
        $('body, html').animate(
          {
            scrollTop: scrollTop,
          },
          0
        )
      })
    })

    function init_ie() {
      if (navigator.userAgent.indexOf('Trident') != -1) {
        $('body').addClass('ie')
      }
    }

    function init_breakpoints() {
      const width = $(window).width()
      if (width < 576) {
        window.breakpoint = 'xs'
      } else if (width < 768) {
        window.breakpoint = 'sm'
      } else if (width < 992) {
        window.breakpoint = 'md'
      } else if (width < 1200) {
        window.breakpoint = 'lg'
      } else if (width < 1600) {
        window.breakpoint = 'xl'
      } else {
        window.breakpoint = 'xxl'
      }
    }

    function init_isTouchDevice() {
      try {
        document.createEvent('TouchEvent')
        $('body').addClass('touch')
      } catch (e) {
        $('body').addClass('no-touch')
      }
    }

    function init_setFPS() {
      var stats = new Stats()
      stats.showPanel(0)
      if (process.env.NODE_ENV === 'development') {
        document.body.appendChild(stats.dom)
      }

      function animate() {
        stats.begin()
        stats.end()
        requestAnimationFrame(animate)
      }

      requestAnimationFrame(animate)
    }

    function init_responsiveTables() {
      $('.wpb_text_column .wpb_wrapper > table').each(function () {
        $(this).parent().addClass('responsive-table')
      })
    }

    function init_brightness() {
      const store = app.getStore()

      $('#darkmodeSwitch').on('click tap', function () {
        const $body = $('body')
        if (!$(this).hasClass('active')) {
          $(this).addClass('active')
          $body.addClass('darkmode')
          store.dispatch({ type: ACTIVATE_DARKMODE })
        } else {
          $(this).removeClass('active')
          $body.removeClass('darkmode')
          store.dispatch({ type: DEACTIVATE_DARKMODE })
        }
      })
    }

    function init_heroclouds() {
      //const divContainer = $('.dhsv_clouds')[0]
      $('.dhsv_clouds').each(function () {
        if ($(this)[0]) {
          const animSegment = [0, 44]
          const params = {
            container: $(this)[0],
            renderer: 'svg',
            loop: false,
            autoplay: false,
            animationData: animationData.default,
            rendererSettings: {
              preserveAspectRatio: 'none',
            },
          }

          let animation = lottie.loadAnimation(params)

          animation.addEventListener('complete', () =>
            playLoop(animation, animSegment)
          )
          $(document).ready(startAnim(animation))
        }
      })

      function startAnim(animation) {
        const startFrame = Math.floor(Math.random() * 10 + 1)
        const randomStartSegment = [startFrame, 44]
        animation.setSpeed(0.025)
        animation.playSegments(randomStartSegment, false)
      }

      function playLoop(animation, animSegment) {
        animation.loop = true
        animation.playSegments(animSegment, false)
      }
    }

    function init_sitemap() {
      $('footer .sitemap .head').on('click tap', function () {
        const e = $(this)
        const headtext = e.find('span')
        const parent = e.parents('.sitemap')
        const content = e.next()

        if (parent.hasClass('active')) {
          content.animate(
            {
              height: 0,
            },
            500
          )
          parent.removeClass('active')
          headtext.text('Ausführliche Sitemap öffnen')
        } else {
          content.animate(
            {
              height: content.get(0).scrollHeight,
            },
            500,
            function () {
              content.height('auto')
            }
          )
          parent.addClass('active')
          headtext.text('Ausführliche Sitemap wieder schließen')
        }
      })
    }

    function init_movedown() {
      $('.hero--more #moveDown span').on('click tap', function () {
        window.scroll({
          top:
            $('.hero--more')[0].offsetTop +
            $('.hero--more')[0].scrollHeight -
            80,
          behavior: 'smooth',
        })
      })
    }

    function init_smooth_scrolling_anchors() {
      $(document).on(
        'click',
        'a[href*="#"]:not([href="#"]):not([href*="#vc_images-carousel"]):not(.dhsv_vc_anchor_menu a):not(.collapse_ac):not([data-vc-tabs]):not([data-vc-accordion])',
        function () {
          if (
            location.pathname.replace(/^\//, '') ==
              this.pathname.replace(/^\//, '') &&
            location.hostname == this.hostname
          ) {
            var targetOffset = -199
            var target = $(this.hash)
            //var scrollTop = $(window).scrollTop()
            target = target.length
              ? target
              : $('[name="' + this.hash.slice(1) + '"]')
            if (target.length) {
              var target_pos = target.offset().top
              $('html,body').animate(
                {
                  scrollTop: target_pos + targetOffset,
                },
                1000
              )
              return false
            }
          }
        }
      )
    }

    function init_vcTtaAccordion() {
      let lastActive = $(undefined)
      $(
        '.vc_tta-container .vc_tta-accordion .vc_tta-panels-container .vc_tta-panel-heading'
      ).on('click tap', function () {
        const e = $(this)
        const parent = e.parent()
        const content = e.next()

        if (parent.hasClass('active')) {
          content.animate(
            {
              height: 0,
            },
            500
          )
          parent.removeClass('active')
          lastActive = $(undefined)
        } else {
          lastActive.click()
          content.animate(
            {
              height: content.get(0).scrollHeight + 45,
            },
            500,
            function () {
              content.height('auto')
            }
          )
          parent.addClass('active')
          lastActive = e
        }
      })
    }

    function init_vcTtaTabs() {
      $(
        '.vc_tta-container .vc_tta-tabs .vc_tta-tabs-container .vc_tta-tabs-list .vc_tta-tab'
      ).on('click tap', function () {
        const e = $(this)

        $('body, html').animate(
          {
            scrollTop: e.offset().top - 80,
          },
          500
        )
        const ulparent = $(this).parent()
        const parent = e.parents('.vc_tta-tabs')
        const lastActive = parent.find(
          '.vc_tta-tabs-container .vc_tta-tabs-list .vc_tta-tab.active'
        )

        if (!e.hasClass('active')) {
          lastActive.removeClass('active')
          parent
            .find(
              '.vc_tta-panels-container .vc_tta-panels .vc_tta-panel[data-index="' +
                lastActive.data('index') +
                '"]'
            )
            .removeClass('active')
          e.addClass('active')
          parent
            .find(
              '.vc_tta-panels-container .vc_tta-panels .vc_tta-panel[data-index="' +
                e.data('index') +
                '"]'
            )
            .addClass('active')
        }

        ulparent.scrollLeft(ulparent.scrollLeft() + e.position().left)
      })

      $(window).scroll(function () {
        const scrollTop = $(window).scrollTop()
        $('.vc_tta-tabs').each(function () {
          var eTop = $(this).offset().top
          var eHeight = $(this).height()

          if (scrollTop > eTop - 50 && scrollTop < eTop + eHeight) {
            $(this).find('.vc_tta-tabs-container').addClass('sticky')
          } else {
            $(this).find('.vc_tta-tabs-container').removeClass('sticky')
          }
        })
      })
    }
  },
  finalize() {
    //
  },
}
