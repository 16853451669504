/* global System */
import React from 'react'
import PropTypes from 'prop-types'
import './style.scss'
import AnimateHeight from 'react-animate-height'
import Lottie from 'react-lottie'
import posed from 'react-pose'
import { easing, tween } from 'popmotion'

const animations = [
  () => System.import('scripts/shared/animations/animation-energie.json'),
  () => System.import('scripts/shared/animations/animation-mobilitaet.json'),
  () =>
    System.import('scripts/shared/animations/animation-stadtentwicklung.json'),
  () => System.import('scripts/shared/animations/animation-konsum.json'),
  () => System.import('scripts/shared/animations/animation-campaign.json'),
]

const LottieBox = posed.div({
  draggable: 'x',
  dragEnd: {
    x: 0,
    transition: ({ self, from }) => {
      if (from < 0) {
        if (from * -1 > self.popWidth * 0.5) self.changeAnimation('-')

        return tween({
          from,
          to: from * -1 > self.popWidth * 0.5 ? -1000 : 0,
        })
      } else {
        if (from > self.popWidth * 0.5) self.changeAnimation('+')

        return tween({
          from,
          to: from > self.popWidth * 0.5 ? 1000 : 0,
        })
      }
    },
  },
  fadeIn: {
    x: 0,
    transition: ({ from }) => ({
      type: 'tween',
      from,
      to: 0,
      duration: 0,
    }),
  },
  fadeOut: {
    x: 0,
    transition: ({ self, from }) => ({
      type: 'tween',
      from: self.props.isMobile ? (from < 0 ? -1000 : from > 0 ? 1000 : 0) : 0,
      to: self.props.isMobile ? (from < 0 ? -1000 : 1000) : '150%',
      ease: easing.backIn,
      duration: 1000,
    }),
  },
})

class HeroHome extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeAnimation: 0,
      lastAnimation: false,
      fadeOutAnimation: false,
      loaded: false,
    }

    this.popWidth = false
    this.popX = 0
    this.preventUpdate = false

    this.heroRef = React.createRef()
    this.popRef = React.createRef()
    this.animationRef = React.createRef()

    this.defaultOptions = {
      renderer: 'svg',
      loop: false,
      autoplay: false,
    }

    this.playLoop = this.playLoop.bind(this)
    this.changeAnimation = this.changeAnimation.bind(this)
    this.slideLoop = this.slideLoop.bind(this)
    this.moveDown = this.moveDown.bind(this)
    this.onSlideDragStart = this.onSlideDragStart.bind(this)
    this.onSlideDragEnd = this.onSlideDragEnd.bind(this)
    this.onSlideComplete = this.onSlideComplete.bind(this)
  }

  componentDidMount() {
    this.animationRef.current.anim.setSpeed(1)
    animations[0]().then(animation => {
      this.defaultOptions.animationData = animation.default
      setTimeout(
        function () {
          this.setState({ loaded: true })
          this.animationRef.current.anim.playSegments([0, 68], true)

          this.animationRef.current.anim.addEventListener(
            'complete',
            this.playLoop
          )

          this.popWidth = this.popRef.current.clientWidth

          this.slideLoop()
        }.bind(this),
        500
      )
    })
  }

  componentDidUpdate() {
    if (!this.state.fadeOutAnimation && !this.preventUpdate) {
      this.animationRef.current.anim.setSpeed(1)
      this.animationRef.current.anim.playSegments([0, 68], true)
      this.animationRef.current.anim.addEventListener('complete', this.playLoop)
    } else {
      animations[this.state.activeAnimation]().then(animation => {
        setTimeout(() => {
          this.slideLoop()
          this.defaultOptions = {
            animationData: animation.default,
          }
          this.setState({ fadeOutAnimation: false })
        }, 1000)
      })
    }
  }

  playLoop() {
    this.animationRef.current.anim.setSpeed(0.2)
    this.animationRef.current.anim.playSegments([100, 240], false)
    setTimeout(
      function () {
        if (window.fps < 18) {
          this.animationRef.current.anim.pause()
        }
      }.bind(this),
      1000
    )
  }

  changeAnimation(clickedAnimation) {
    const { slides } = this.props
    const { activeAnimation, fadeOutAnimation } = this.state

    if (clickedAnimation === '+') {
      if (activeAnimation + 1 === slides.length) {
        clickedAnimation = 0
      } else {
        clickedAnimation = activeAnimation + 1
      }
    } else if (clickedAnimation === '-') {
      if (activeAnimation - 1 < 0) {
        clickedAnimation = slides.length - 1
      } else {
        clickedAnimation = activeAnimation - 1
      }
    }

    this.preventUpdate = false

    if (clickedAnimation !== activeAnimation && !fadeOutAnimation) {
      this.setState(prevState => ({
        fadeOutAnimation: true,
        lastAnimation: prevState.activeAnimation,
        activeAnimation: clickedAnimation,
      }))
    }
  }

  slideLoop() {
    const { slides } = this.props
    const self = this

    clearTimeout(this.timeoutLoop)
    this.timeoutLoop = null

    this.timeoutLoop = setTimeout(function () {
      const { activeAnimation } = self.state

      if (activeAnimation === slides.length - 1) {
        self.changeAnimation(0)
      } else {
        self.changeAnimation(activeAnimation + 1)
      }
      self.slideLoop()
    }, 15000)
  }

  moveDown() {
    window.scroll({
      top: this.heroRef.current.scrollHeight - 80,
      behavior: 'smooth',
    })
  }

  onSlideDragStart() {
    this.preventUpdate = true
  }

  onSlideDragEnd() {
    this.popWidth = this.popRef.current.clientWidth
  }

  onSlideComplete() {
    this.preventUpdate = false
  }

  render() {
    const { slides } = this.props
    const {
      activeAnimation,
      lastAnimation,
      fadeOutAnimation,
      loaded,
    } = this.state

    return (
      <div className={`hero_home ${loaded ? 'loaded' : ''}`} ref={this.heroRef}>
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-xl-5 ctn">
              <nav>
                {slides.map((item, index) => (
                  <div
                    key={index}
                    className={index === activeAnimation ? 'active' : ''}
                    onClick={() => this.changeAnimation(index)}
                  >
                    <span>
                      <i className="la la-arrow-right" />
                      {item.title}
                    </span>
                    <AnimateHeight
                      className="info"
                      height={index === activeAnimation ? 'auto' : 0}
                      delay={index === activeAnimation ? 1000 : 0}
                      duration={1000}
                    >
                      <h2>{slides[activeAnimation].line1}</h2>
                      <p className="h2 light">
                        {slides[activeAnimation].line2}
                      </p>
                      {item.link ? (
                        <div className="button">
                          <a
                            className="btn"
                            href={item.link.url}
                            target={item.link.target}
                          >
                            {item.link.title}
                            <i className="la la-arrow-right" />
                          </a>
                        </div>
                      ) : (
                        <div className="buttons">
                          <a
                            className="btn"
                            href={slides[activeAnimation].linkInformieren}
                          >
                            Informieren
                            <i className="la la-arrow-right" />
                          </a>
                          <a
                            className="btn"
                            href={slides[activeAnimation].linkMitmachen}
                          >
                            Mitmachen
                            <i className="la la-arrow-right" />
                          </a>
                        </div>
                      )}
                    </AnimateHeight>
                  </div>
                ))}
              </nav>
            </div>
            <div className="col-lg-5 col-xl-5 ctn ctn-mobile">
              <nav>
                {slides.map((item, index) => (
                  <div
                    key={index}
                    className={index === activeAnimation ? 'active' : ''}
                    onClick={() => this.changeAnimation(index)}
                  />
                ))}
              </nav>
              {fadeOutAnimation ? (
                <div className="info fadeOut">
                  <p className="upper">{slides[lastAnimation].title}</p>
                  <h2>{slides[lastAnimation].line1}</h2>
                  <p className="h2 light">{slides[lastAnimation].line2}</p>
                  {slides[lastAnimation].link ? (
                    <div className="button">
                      <a
                        className="btn"
                        href={slides[lastAnimation].link.url}
                        target={slides[lastAnimation].link.target}
                      >
                        {slides[lastAnimation].link.title}
                        <i className="la la-arrow-right" />
                      </a>
                    </div>
                  ) : (
                    <div className="buttons">
                      <a
                        className="btn"
                        href={slides[lastAnimation].linkInformieren}
                      >
                        Informieren
                        <i className="la la-arrow-right" />
                      </a>
                      <a
                        className="btn"
                        href={slides[lastAnimation].linkMitmachen}
                      >
                        Mitmachen
                        <i className="la la-arrow-right" />
                      </a>
                    </div>
                  )}
                </div>
              ) : (
                <div className="info fadeIn">
                  <p className="upper">{slides[activeAnimation].title}</p>
                  <h2>{slides[activeAnimation].line1}</h2>
                  <p className="h2 light">{slides[activeAnimation].line2}</p>
                  {slides[activeAnimation].link ? (
                    <div className="button">
                      <a
                        className="btn"
                        href={slides[activeAnimation].link.url}
                        target={slides[activeAnimation].link.target}
                      >
                        {slides[activeAnimation].link.title}
                        <i className="la la-arrow-right" />
                      </a>
                    </div>
                  ) : (
                    <div className="buttons">
                      <a
                        className="btn"
                        href={slides[activeAnimation].linkInformieren}
                      >
                        Informieren
                        <i className="la la-arrow-right" />
                      </a>
                      <a
                        className="btn"
                        href={slides[activeAnimation].linkMitmachen}
                      >
                        Mitmachen
                        <i className="la la-arrow-right" />
                      </a>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="col-lg-7 col-xl-7 lottie">
              <LottieBox
                ref={this.popRef}
                pose={
                  fadeOutAnimation
                    ? this.preventUpdate
                      ? 'foo'
                      : 'fadeOut'
                    : 'fadeIn'
                }
                self={this}
                onDragStart={this.onSlideDragStart}
                onDragEnd={this.onSlideDragEnd}
                onPoseComplete={this.onSlideComplete}
                onValueChange={{
                  x: x => {
                    this.popX = x
                  },
                }}
              >
                <Lottie
                  ref={this.animationRef}
                  options={this.defaultOptions}
                  speed={0.2}
                  isClickToPauseDisabled={true}
                />
              </LottieBox>
            </div>
            <div id="moveDown">
              <span onClick={this.moveDown} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

HeroHome.propTypes = {
  slides: PropTypes.array,
}

export default HeroHome
