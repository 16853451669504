import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createSelector } from 'reselect'

import Component from './component'

import {
  sSearchTerm,
  sIsEmptySearchResult,
  activate,
  updateInput,
} from '../../redux/modules/search'
import { sHasNextPage } from '../../redux/modules/searchPosts'
import { loadSearchPosts } from '../../redux/modules/router'
import { sIsMobile } from '../../redux/modules/browser'

export default connect(
  (state, props) => ({
    hasNextPage: sHasNextPage(state),
    searchTerm: sSearchTerm(state),
    firstPageLoaded: state.searchPosts.firstPageLoaded,
    page: state.searchPosts.page,
    totalPosts: state.searchPosts.totalPosts,
    isLoading: state.searchPosts.isLoading,
    posts: state.searchPosts.data,
    pageTitle: sPageTitle(state, props),
    isMobile: sIsMobile(state, props),
    isEmptySearchResult: sIsEmptySearchResult(state, props),
  }),
  dispatch =>
    bindActionCreators(
      {
        loadMore: () => loadSearchPosts,
        newSearch: () => dispatch => {
          dispatch(updateInput(''))
          dispatch(activate())
        },
      },
      dispatch
    )
)(Component)

const sTitleTemplate = (_, { titleTemplate }) => titleTemplate
const sSiteTitle = (_, { siteTitle }) => siteTitle

export const sPageTitle = createSelector(
  sTitleTemplate,
  sSearchTerm,
  sSiteTitle,
  (titleTemplate, searchTerm, siteTitle) =>
    titleTemplate
      .replace('%%searchphrase%%', searchTerm)
      .replace('%%sitename%%', siteTitle)
      .replace(/%%[^%]+%%/g, '')
)
