import { createSelector } from 'reselect'
import { UPDATE_INPUT, DEACTIVATE, gotoSearch } from './search'
import { sSuggestions } from './searchSuggestions'

export const UPDATE_SELECTION = 'bos001/searchKeyControl/UPDATE_SELECTION'

const initialState = {
  currentSelection: null,
}
export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_INPUT:
    case DEACTIVATE:
      return initialState

    case UPDATE_SELECTION:
      return {
        ...state,
        currentSelection: action.index,
      }

    default:
  }
  return state
}

export const sCurrentSelection = state =>
  state.searchKeyControl.currentSelection

export const sIsSuggestionSelected = createSelector(
  state => state.searchKeyControl.currentSelection,
  currentSelection => currentSelection !== null
)

export const updateSelection = down => (dispatch, getState) => {
  const state = getState()

  const currentSelection = sCurrentSelection(state)
  const suggestions = sSuggestions(state)

  const suggestionsCount = suggestions.length

  if (!suggestionsCount) {
    return
  }

  if (currentSelection === null) {
    return dispatch({
      type: UPDATE_SELECTION,
      index: down ? 0 : suggestionsCount - 1,
    })
  }

  if (down) {
    const nextSelection = currentSelection + 1
    return dispatch({
      type: UPDATE_SELECTION,
      index: nextSelection < suggestionsCount ? nextSelection : 0,
    })
  } else {
    const nextSelection = currentSelection - 1
    return dispatch({
      type: UPDATE_SELECTION,
      index: nextSelection < 0 ? suggestionsCount - 1 : nextSelection,
    })
  }
}

export const activateCurrentSelection = () => (dispatch, getState) => {
  const state = getState()

  const currentSelection = sCurrentSelection(state)
  const suggestions = sSuggestions(state)

  if (!suggestions.length || currentSelection === null) {
    return
  }

  const suggestion = suggestions[currentSelection]

  return dispatch(gotoSearch(suggestion))
}
