import $ from 'jquery'

$(document).ready(ankerInit)
$(document).ready(ankerSelect)

$(window).on('scroll', ankerScroll)

function ankerSelect() {
  $('.dhsv_vc_anker select').on('change', function () {
    var val = $(this).val()
    console.log(val) //eslint-disable-line
    $('body, html').animate(
      {
        scrollTop: $('#' + val).offset().top - 199,
      },
      1000
    )
  })
}

function ankerScroll() {
  var scrolled = $(window).scrollTop()
  var point = $('.ankerpoint')

  $('.dhsv_vc_anker').each(function () {
    var tabs = $(this).find('.ankernav')
    var eTop = $(this).offset().top

    if (eTop <= scrolled + 95) {
      $(tabs).addClass('anker-sticky')
    } else {
      $(tabs).removeClass('anker-sticky')
    }
  })
  point.each(function (index) {
    var element = $(this)
    var eID = element.attr('id')
    var eTop = element.offset().top

    if (
      index !== point.length - 1 &&
      point.eq(index + 1).offset().top <= scrolled + 200
    ) {
      $('[href="#' + eID + '"]')
        .parent()
        .removeClass('anker-active')
    } else {
      if (eTop <= scrolled + 200) {
        $('[href="#' + eID + '"]')
          .parent()
          .addClass('anker-active')
        //var coff = $('.dhsv_vc_anker .container-fluid').position()
        var activeoffset = $('.anker-active').position()
        if (activeoffset) {
          //console.log(coff) //eslint-disable-line
          //$('.anker-active').html(activeoffset.left)
          $('.activebar').css('left', activeoffset.left)
        }
      } else {
        $('[href="#' + eID + '"]')
          .parent()
          .removeClass('anker-active')
      }
    }
  })
}

function ankerInit() {
  var point = $('.ankerpoint')
  var count = point.length
  var i = 0
  var nav = $('.ankernav')
  if (nav.length > 0) {
    $('body').addClass('hasanker')
  }
  var ul = nav.find('ul')
  var select = nav.find('select')

  ul.html('')
  point.each(function () {
    i++
    var e = $(this)
    var eName = e.attr('data-ankername')
    var eID = e.attr('id')
    nav.addClass('hidenav')

    $('.activebar').css('width', 100 / count + '%')

    ul.append(
      '<li class="ankernav-li"><a href="#' +
        eID +
        '" class="ankernav-item"><p><span>' +
        eName +
        '</span></p></a></li>'
    )

    select.append('<option value="' + eID + '">' + eName + '</option>')

    if (i == 1) {
      var inside = $('.ankernav.inside a.down')
      inside.attr('href', '#' + eID)
    }
  })

  $('.buttoncta').click(function () {
    $('.modal').addClass('openmodal')
  })

  $('.modal')
    .find('.closemodal')
    .click(function () {
      $('.modal').removeClass('openmodal')
    })
}
