import React from 'react'
import PropTypes from 'prop-types'
import './style.scss'
import Cookies from 'universal-cookie'

const cookies = new Cookies()

class AllActionCo2 extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      percent: 0,
      add: false,
    }
    this.getPercent = this.getPercent.bind(this)
  }

  componentDidMount() {
    this.getPercent()
    var lastCookie = cookies.get('JoinAktion') // 'static' memory between function calls

    setInterval(
      function () {
        var currentCookie = cookies.get('JoinAktion')
        //console.log(currentCookie + '!=' + lastCookie) //eslint-disable-line
        if (currentCookie != lastCookie) {
          this.getPercent()
          this.setState({ add: true })

          setTimeout(
            function () {
              this.setState({ add: false })
            }.bind(this),
            4000
          )

          lastCookie = currentCookie // store latest cookie
        }
      }.bind(this),
      1000
    ) // run every 100 ms
  }

  getPercent() {
    const ids = cookies.get('JoinAktion') ? cookies.get('JoinAktion') : ''

    fetch(`/wp-json/dhsv_actions/v1/co2/?ids=${ids}`, {
      method: 'GET',
    })
      .then(response => {
        return response.json().then(json => {
          this.setState({ percent: json })
        })
      })
      .catch(error => {
        this.setState({ error: error })
      })
  }

  render() {
    return !this.state.error ? (
      <div className={`co2-box ${this.state.add ? 'add' : ''}`}>
        <span className="title">
          CO<sub>2</sub> Ersparnis
        </span>
        <span className="number">{this.state.percent}%</span>
      </div>
    ) : null
  }
}

AllActionCo2.propTypes = {
  isMobile: PropTypes.bool,
}

export default AllActionCo2
