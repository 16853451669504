import React from 'react'
import PropTypes from 'prop-types'
import AnimateHeight from 'react-animate-height'

class Filter extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: this.props.open,
      toggleText: this.props.toggleText.close,
    }
    this.toggleFilter = this.toggleFilter.bind(this)
    this.MouseClick = this.MouseClick.bind(this)
  }

  toggleFilter() {
    this.setState({
      open: !this.state.open,
      toggleText: this.state.open
        ? this.props.toggleText.close
        : this.props.toggleText.open,
    })
  }

  MouseClick(item) {
    this.props.onChange(item)
  }

  render() {
    const { items, activeFilter } = this.props
    return (
      <div className="filter-row">
        <div
          className={'filter-toggle-row' + (this.state.open ? ' active' : '')}
        >
          <span className="filter-toggle" onClick={this.toggleFilter}>
            {this.state.toggleText}
          </span>
          {activeFilter ? (
            <ul>
              <li onClick={() => this.MouseClick(activeFilter)}>
                <b>{activeFilter}</b>
              </li>
            </ul>
          ) : null}
        </div>
        <AnimateHeight
          height={this.state.open || this.props.isMobile ? 'auto' : 0}
          duration={500}
        >
          <div className={'filter-body' + (this.state.open ? ' active' : '')}>
            {this.props.isMobile ? (
              <div className="filter-mobile-close-row">
                <span>Filter</span>
                <i className="la la-close" onClick={this.toggleFilter} />
              </div>
            ) : null}
            <div className="filterinner">
              <div className="filtersection">
                <ul className="taglist">
                  {items
                    .filter(item => item)
                    .map((item, k) => (
                      <li
                        onClick={() => this.MouseClick(item)}
                        key={k}
                        className={activeFilter === item ? 'active' : ''}
                      >
                        {item}
                      </li>
                    ))}
                </ul>
              </div>
            </div>
            <button className="submit" onClick={this.toggleFilter}>
              Filter anwenden
            </button>
          </div>
        </AnimateHeight>
      </div>
    )
  }
}

Filter.propTypes = {
  isMobile: PropTypes.bool,
  open: PropTypes.bool,
  toggleText: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  activeFilter: PropTypes.string,
  items: PropTypes.array,
}

Filter.defaultProps = {
  open: false,
  toggleText: {
    open: 'Filter Schließen',
    close: 'Themen filtern',
  },
}

export default Filter
