import React from 'react'
import { postShape } from '../../shared/shapes'

const Item = ({
  title: { rendered: title },
  link,
  type,
  acf: {
    url: externalLink,
    datei: { url: downloadLink, filename, mime_type } = {},
  },
}) => {
  const isExternal = type === 'external'
  const isDownload = type === 'downloads'

  const href = (() => {
    if (isExternal) return externalLink
    if (isDownload) return downloadLink

    return link
  })()

  const linkProps = isDownload ? { download: filename, type: mime_type } : {}

  return (
    <a
      href={href}
      className="item"
      target={isExternal || isDownload ? '_blank' : ''}
      rel={isExternal || isDownload ? 'noopener noreferrer' : ''}
      {...linkProps}
    >
      <p
        dangerouslySetInnerHTML={{
          __html: title,
        }}
      />{' '}
      {isExternal ? <i className="la la-external-link" /> : null}
      {isDownload ? <i className="la la-download" /> : null}
    </a>
  )
}

Item.propTypes = postShape

export default Item
