import React from 'react'
import PropTypes from 'prop-types'
import CountUp, { startAnimation } from 'react-countup'

class CountNumber extends React.Component {
  constructor(props) {
    super(props)
    this.countNumber = React.createRef()
    this.countUp = React.createRef()
    this.state = { countStarted: false }
    this.handleScroll = this.handleScroll.bind(this)
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll() {
    const { countStarted } = this.state
    const eTop = $(this.countNumber.current).offset().top
    const wHeight = $(window).height()
    const scrollTop = $(window).scrollTop()

    if (
      !countStarted &&
      this.countUp.current &&
      scrollTop >= eTop - wHeight / 2
    ) {
      this.setState({
        countStarted: true,
      })
      startAnimation(this.countUp.current)
    }
  }

  render() {
    const { number, prefix, rand, suffix } = this.props
    return (
      <div className={`dhsv_count_number number`} ref={this.countNumber}>
        {number ? (
          <CountUp
            className="number tw-color-1-100"
            decimal="."
            duration={3}
            end={Math.floor(number)}
            id={`number_${rand}`}
            prefix={prefix}
            ref={this.countUp}
            separator="."
            start={0}
            suffix={suffix}
            useEasing={true}
            useGrouping={true}
          />
        ) : (
          <span className="number">{prefix}</span>
        )}
      </div>
    )
  }
}

CountNumber.propTypes = {
  number: PropTypes.number,
  prefix: PropTypes.string,
  rand: PropTypes.number,
  suffix: PropTypes.string,
}

export default CountNumber
