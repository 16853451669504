import React from 'react'
import PropTypes from 'prop-types'
import FilterThemen from '../FilterThemen'
import FilterPointRange from '../FilterPointRange'
import AnimateHeight from 'react-animate-height'

class Filter extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: this.props.open,
      toggleText: this.props.open
        ? this.props.toggleText.open
        : this.props.toggleText.close,
    }
    this.toggleFilter = this.toggleFilter.bind(this)
  }

  toggleFilter() {
    this.setState(prevState => ({
      open: !prevState.open,
      toggleText: !prevState.open
        ? this.props.toggleText.open
        : this.props.toggleText.close,
    }))
  }

  render() {
    const { isMobile } = this.props
    return (
      <div className="filter-row">
        <div
          className={'filter-toggle-row' + (this.state.open ? ' active' : '')}
        >
          <span className="filter-toggle" onClick={this.toggleFilter}>
            {this.state.toggleText}
          </span>
          {this.props.activeFilter ? (
            <ul>
              {Object.keys(this.props.activeFilter).map((item, k) => (
                <li key={k}>
                  {item} <b>{this.props.activeFilter[item]}</b>
                </li>
              ))}
            </ul>
          ) : null}
          <a className="btn btn-primary" href="#co2abdruck">
            Erklärung
          </a>
        </div>
        <AnimateHeight
          height={this.state.open || isMobile ? 'auto' : 0}
          duration={500}
        >
          <div className={'filter-body' + (this.state.open ? ' active' : '')}>
            {this.props.isMobile ? (
              <div className="filter-mobile-close-row">
                <span>Filter</span>
                <i className="la la-close" onClick={this.toggleFilter} />
              </div>
            ) : null}
            <div className="filterinner">
              <FilterThemen title="Themen" />
              <FilterPointRange
                title="Aufwand"
                onChange={this.props.onChange('aufwand')}
              />
              <FilterPointRange
                title="Einfluss"
                onChange={this.props.onChange('einfluss')}
              />
            </div>
            <button className="submit" onClick={this.toggleFilter}>
              Filter anwenden
            </button>
          </div>
        </AnimateHeight>
      </div>
    )
  }
}

Filter.propTypes = {
  isMobile: PropTypes.bool,
  open: PropTypes.bool,
  toggleText: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  activeFilter: PropTypes.object,
}

Filter.defaultProps = {
  open: false,
  toggleText: {
    open: 'Filter Schließen',
    close: 'Aktionen filtern',
  },
}

export default Filter
