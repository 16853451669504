const initialState = false
export const ACTIVATE_DARKMODE = 'amd001/stickyHeader/ACTIVATE_DARKMODE'
export const DEACTIVATE_DARKMODE = 'amd001/stickyHeader/DEACTIVATE_DARKMODE'

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTIVATE_DARKMODE:
      return true
    case DEACTIVATE_DARKMODE:
      return false
    default:
  }
  return state
}
