import React from 'react'
import PropTypes from 'prop-types'
import { combineReducers } from 'redux'
import postsReducer, { loadPosts } from '../AktionenArchive/postState'
import './style.scss'

const reducer = combineReducers({
  posts: postsReducer,
})

class FilterThemen extends React.Component {
  constructor(props) {
    super(props)
    this.state = reducer()
    this.MouseClick = this.MouseClick.bind(this)
    this.dispatch = this.dispatch.bind(this)
    this.loadPosts = this.loadPosts.bind(this)
  }

  dispatch(action) {
    if (typeof action === 'function') {
      action(this.dispatch, () => this.state)
    } else {
      this.setState(prevState => reducer(prevState, action))
    }
  }

  loadPosts() {
    const postType = 'klimathema-aktion'
    this.dispatch(loadPosts({ postType }))
  }

  componentDidMount() {
    this.loadPosts()
  }

  MouseClick(value) {
    var active = this.props.themen === value ? null : value
    this.props.changeThema(active)
  }

  render() {
    const { data } = this.state.posts
    const { title, themen } = this.props
    //console.log(data) //eslint-disable-line

    return (
      <div className={'dhsv_filter_themen'}>
        <div className="headline">{title}</div>
        <ul className={'taglist'}>
          {data.map((value, key) => (
            <li
              className={themen === value.slug ? 'active' : null}
              key={key}
              onClick={() => this.MouseClick(value.slug)}
            >
              {value.title.rendered}
            </li>
          ))}
        </ul>
      </div>
    )
  }
}

FilterThemen.propTypes = {
  isMobile: PropTypes.bool,
  themen: PropTypes.string,
  title: PropTypes.string,
  changeThema: PropTypes.func,
}

export default FilterThemen
