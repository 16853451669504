/* global System */
import React from 'react'
import PropTypes from 'prop-types'
import './style.scss'
import FilterPointRange from '../FilterPointRange'
import JoinActionButton from '../AktionenArchive/joinActionButton'
import Cookies from 'universal-cookie'
import Lottie from 'react-lottie'

const cookies = new Cookies()

class AktionItem extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      joinClicked: false,
      joined: false,
      teilgenommen: this.props.item.fields.teilgenommen
        ? this.props.item.fields.teilgenommen
        : 0,
      iconData: null,
    }
    this.joinClicked = this.joinClicked.bind(this)
    this.closeJoin = this.closeJoin.bind(this)
    this.loadIcon = this.loadIcon.bind(this)
  }

  joinClicked(clicked) {
    const { teilgenommen } = this.state
    this.setState({
      joinClicked: clicked,
      joined: clicked,
      teilgenommen: parseInt(teilgenommen) + 1,
    })
  }

  closeJoin() {
    this.setState({
      joinClicked: false,
    })
  }

  loadIcon() {
    System.import('images/icons/' + this.props.item.slug)
      .then(icon => this.setState({ iconData: icon.default }))
      .catch(() => {
        console.warn('Icon wurde nicht gefunden!!! -> ' + this.props.item.slug) //eslint-disable-line
        this.setState({ iconData: require('images/icons/default') })
      })
  }

  componentDidMount() {
    const getJoin = cookies.get('JoinAktion')
    const joinAction = getJoin
      ? cookies
          .get('JoinAktion')
          .split(',')
          .map(item => parseInt(item))
      : []
    if (joinAction.includes(this.props.item.id)) {
      this.setState({
        joined: true,
      })
    }

    this.loadIcon()
  }

  render() {
    const { item } = this.props
    const { teilgenommen, joinClicked, joined, iconData } = this.state

    const teaser_text =
      item.content.rendered.length > 230
        ? item.content.rendered.slice(0, 230) + '…'
        : item.content.rendered

    return (
      <div className={'dhsv_aktion_item' + (joinClicked ? ' clicked' : '')}>
        <div className="inner hover">
          {iconData ? (
            <div className="iconwrapper">
              <Lottie
                options={{
                  renderer: 'svg',
                  loop: false,
                  autoplay: true,
                  animationData: iconData,
                }}
                speed={1}
                isClickToPauseDisabled={true}
              />
            </div>
          ) : null}
          <h3>{item.fields.headline}</h3>
          {item.fields.teaser_text ? (
            <p>item.fields.teaser_text</p>
          ) : (
            <p dangerouslySetInnerHTML={{ __html: teaser_text }} />
          )}
        </div>
        <div className="inner" data-bg={item.fields.einfluss}>
          <div className="clickedJoin">
            <div className="innerjoin">
              <i className="la la-close" onClick={this.closeJoin} />
              <div className="join-wrapper">
                <span>Danke! Sie gehören zu den</span>
                <span className="number">{teilgenommen}</span>
                <span>
                  Proklima Botschaftern, die an der Aktion teilnehmen.
                </span>
              </div>
              <div className="save-wrapper">
                <span>
                  Persönliche CO<sub>2</sub> Ersparnis
                </span>
                <span className="number">{item.fields.gesamtersparnis}</span>
              </div>
            </div>
          </div>

          {iconData ? (
            <div className="iconwrapper">
              <Lottie
                options={{
                  renderer: 'svg',
                  loop: false,
                  autoplay: true,
                  animationData: iconData,
                }}
                speed={1}
                isClickToPauseDisabled={true}
              />
            </div>
          ) : null}
          <h3>{item.fields.headline}</h3>
          <div className="facts">
            <FilterPointRange
              display={true}
              value={parseInt(item.fields.aufwand)}
              title="Aufwand"
            />
            <FilterPointRange
              display={true}
              value={parseInt(item.fields.einfluss)}
              title="Einfluss"
            />
          </div>
          <div className="join-count" />
          <div className="actions">
            <a
              className={'details' + (joined ? ' joined' : '')}
              href={item.link}
            >
              Details
            </a>
            <JoinActionButton
              item={item}
              id={parseInt(item.id)}
              title="Mitmachen"
              clicked={this.joinClicked}
            />
          </div>
        </div>
      </div>
    )
  }
}

AktionItem.propTypes = {
  item: PropTypes.object,
}

export default AktionItem
