import $ from 'jquery'
import Flickity from 'flickity'

$(document).ready(function () {
  dhsv_vc_kennzahlen()
})

$(window).resize(function () {
  $('.dhsv_vc_kennzahlen').each(function () {
    const slider = $(this).find('section')
    const points = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl']
    const breakpoint = window.breakpoint
    slider.find('.item').each(function () {
      const count = $(this).attr('data-count')
      const el = $(this)
      $.each(points, function (i, v) {
        if (breakpoint === v) {
          const width = count > i ? 100 / (i + 1) : 100 / count
          el.css('width', width + '%')
        }
      })
    })
  })
})

function dhsv_vc_kennzahlen() {
  $('.dhsv_vc_kennzahlen').each(function () {
    const slider = $(this).find('section')

    new Flickity(slider[0], {
      cellAlign: 'left',
      contain: true,
      groupCells: true,
      draggable: true,
    })
  })
}
