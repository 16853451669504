import React from 'react'
import PropTypes from 'prop-types'
import './style.scss'

class TeaserBoxTestimonial extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { item } = this.props

    return (
      <div className="dhsv_teaserbox_testimonial" data-id={item.id}>
        <div className="ff59box">
          <a
            href={item.link}
            className="circle"
            style={{ backgroundImage: 'url("' + item.image + '")' }}
          >
            <div>
              <span className="p">Mehr erfahren</span>
            </div>
          </a>
        </div>
        <div className="meta">
          <p className="intro">{item.name}</p>
          <p>{item.description}</p>
        </div>
      </div>
    )
  }
}

TeaserBoxTestimonial.propTypes = {
  item: PropTypes.object,
}

export default TeaserBoxTestimonial
