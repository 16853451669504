import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Component from './component'
import PropTypes from 'prop-types'
import { sIsMobile } from '../../redux/modules/browser'
import {
  sInputString,
  activate,
  updateInput,
  gotoSearch,
} from '../../redux/modules/search'

const WithData = connect(
  state => ({
    isMobile: sIsMobile(state),
    inputString: sInputString(state),
    active: state.search.active,
  }),
  dispatch =>
    bindActionCreators(
      {
        activate,
        updateInput,
        gotoSearch,
      },
      dispatch
    )
)(Component)

WithData.propTypes = {
  isMobile: PropTypes.bool,
}

export default WithData
