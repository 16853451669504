import React from 'react'
import PropTypes from 'prop-types'
import Link from 'redux-first-router-link'
import { SEARCH } from '../../redux/modules/routes'

const Suggestion = ({ suggestion, selected, isSearch }) => (
  <li className={selected ? 'selected' : ''}>
    {isSearch ? (
      <Link
        to={{
          type: SEARCH,
          payload: { search: suggestion },
        }}
      >
        {suggestion}
      </Link>
    ) : (
      <a href={`/search/${suggestion}`}>{suggestion}</a>
    )}
  </li>
)

Suggestion.propTypes = {
  suggestion: PropTypes.string,
  selected: PropTypes.bool,
  isSearch: PropTypes.bool,
}

class SearchSuggestions extends React.Component {
  render() {
    const {
      suggestions,
      currentSelection,
      haveSuggestions,
      isSearch,
    } = this.props

    if (!haveSuggestions) {
      return null
    }

    return (
      <div className="search-suggestions">
        <div className="container">
          <ul className="list">
            {suggestions.map((suggestion, i) => (
              <Suggestion
                suggestion={suggestion}
                selected={currentSelection === i}
                key={i}
                isSearch={isSearch}
              />
            ))}
          </ul>
        </div>
      </div>
    )
  }
}

SearchSuggestions.propTypes = {
  suggestions: PropTypes.arrayOf(PropTypes.string),
  currentSelection: PropTypes.number,
  haveSuggestions: PropTypes.bool,
  isSearch: PropTypes.bool,
}

export default SearchSuggestions
