import { createSelector } from 'reselect'
import toLower from 'ramda/es/toLower'
import { sTotalPosts } from './searchPosts'

export const ACTIVATE = 'bos001/search/ACTIVATE'
export const DEACTIVATE = 'bos001/search/DEACTIVATE'

export const UPDATE_INPUT = 'bos001/search/UPDATE_INPUT'

import { SEARCH, FRONTPAGE, sIsSearch } from './routes'

const initialState = {
  active: false, // is the search area extended?
  inputString: '', // curent value in search <input />
}
export default (state = initialState, action) => {
  switch (action.type) {
    case ACTIVATE:
      return {
        ...state,
        active: true,
      }

    case DEACTIVATE:
      return {
        ...state,
        active: false,
        inputString:
          state.inputString.trim().length > 0 ? state.inputString : '',
      }

    case UPDATE_INPUT:
      return {
        ...state,
        inputString: action.payload.string,
      }

    case SEARCH:
      return {
        ...state,
        inputString: action.payload.search,
      }

    case FRONTPAGE:
      return {
        ...state,
        inputString: '',
        active: false,
      }
    default:
  }
  return state
}

export const activate = () => ({ type: ACTIVATE })
export const deactivate = () => ({ type: DEACTIVATE })

export const updateInput = string => ({
  type: UPDATE_INPUT,
  payload: { string },
})

export const sInputString = createSelector(
  state => state.search.inputString,
  toLower
)

export const sSearchTermExists = createSelector(
  sInputString,
  searchTerm => searchTerm.trim().length > 0
)

export const sSearchStringFromLocation = state => state.location.payload.search

export const sIsEmptySearchResult = createSelector(
  sIsSearch,
  sTotalPosts,
  (isSearchRoute, totalPosts) => isSearchRoute && totalPosts === 0
)

export const gotoSearch = search => (dispatch, getState) => {
  const isSearch = sIsSearch(getState())

  if (isSearch) {
    return dispatch({ type: SEARCH, payload: { search } })
  } else {
    window.location.assign(`/search/${search}`)
  }
}

const sRouterState = s => s.location

export const sSearchTerm = createSelector(
  sRouterState,
  ({ payload: { search } }) => search
)
