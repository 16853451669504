import compose from 'ramda/es/compose'
import map from 'ramda/es/map'
import replace from 'ramda/es/replace'
import zip from 'ramda/es/zip'
import apply from 'ramda/es/apply'
import toPairs from 'ramda/es/toPairs'
import join from 'ramda/es/join'
import sortBy from 'ramda/es/sortBy'
import filter from 'ramda/es/filter'
import prop from 'ramda/es/prop'

// strip html tags
// see: https://stackoverflow.com/questions/5002111/how-to-strip-html-tags-from-string-in-javascript/5002161#5002161
export const stripTags = str => {
  const div = document.createElement('div')
  div.innerHTML = str
  return div.textContent || div.innerText || ''
}

// :: [string] -> [string] -> string -> string
export const replaceStrings = compose(apply(compose), map(apply(replace)), zip)

export const removeUmlauts = replaceStrings(
  ['ä', 'Ä', 'ö', 'Ö', 'ü', 'Ü', 'ß'],
  ['a', 'A', 'o', 'O', 'u', 'U', 'ss']
)

// build query string from an object
// buildQueryString :: object -> string
export const buildQueryString = compose(
  join('&'),
  map(([k, v]) => `${k}=${encodeURIComponent(v)}`),
  filter(([, v]) => !!v),
  sortBy(prop(0)),
  toPairs
)
