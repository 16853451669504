import React from 'react'
import PropTypes from 'prop-types'
import './style.scss'
import Lottie from 'react-lottie'
import * as animationData from '../../shared/animations/logoAnim.json'
import * as animationData2 from '../../shared/animations/logoAnim2.json'
import * as animationData3 from '../../shared/animations/logoAnim3.json'
import wortmarke from '../../../images/ProKlima-Wiesbaden_Wortmarke.svg'
import MobileMenu from './../MobileMenu'
import Search from '../Search'

class DesktopHeader extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeItem: false,
      openMenu: false,
      openTabletmenu: false,
      activeSegment: false,
      sticky: false,
      loaded: false,
    }

    this.headerRef = React.createRef()
    this.logoRef = React.createRef()

    this.defaultOptions = {
      renderer: 'svg',
      loop: false,
      autoplay: false,
      animationData: props.globals.isAltHero
        ? animationData2
        : props.globals.isAktion
        ? animationData3
        : animationData,
    }

    this.handleScroll = this.handleScroll.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this)
    this.switchMenu = this.switchMenu.bind(this)
    this.renderChildren = this.renderChildren.bind(this)
    this.playOutroAnim = this.playOutroAnim.bind(this)
    this.toggleAnim = this.toggleAnim.bind(this)
    this.menuItemClick = this.menuItemClick.bind(this)
    this.toggleSearch = this.toggleSearch.bind(this)
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, true)
    document.addEventListener('mousedown', this.handleClickOutside)
    this.logoRef.current.anim.setSpeed(1.25)
    this.logoRef.current.anim.addEventListener('complete', this.toggleAnim)
    this.logoRef.current.el.parentNode.addEventListener(
      'mouseenter',
      this.playOutroAnim
    )

    const self = this

    document.addEventListener('DOMContentLoaded', () => {
      setTimeout(
        function () {
          self.setState({ loaded: true })
          self.logoRef.current.anim.playSegments([0, 65], false)
        }.bind(self),
        500
      )
    })

    window.dispatchEvent(new CustomEvent('scroll'))

    this.setState({ activeSegment: 'intro' })
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  handleScroll() {
    const { sticky } = this.state
    const scrolltop = Math.max(
      window.pageYOffset,
      document.scrollingElement ? document.scrollingElement.scrollTop : 0,
      document.documentElement ? document.documentElement.scrollTop : 0,
      document.body.scrollTop
    )

    const distance = 100

    if (scrolltop < distance) {
      this.setState({ sticky: false })
      this.toggleAnim()
    } else {
      if (!sticky) {
        this.setState({ sticky: true })
        this.playOutroAnim()
      }
    }
  }

  handleClickOutside(e) {
    if (this.headerRef.current && !this.headerRef.current.contains(e.target)) {
      this.setState({ activeItem: false, openTabletmenu: false })
    }
  }

  switchMenu(menu) {
    const { activeItem } = this.state
    if (activeItem === menu) {
      this.setState({ activeItem: false })
    } else {
      this.setState({ activeItem: menu })
    }
  }

  renderChildren(item) {
    if (item.items) {
      return (
        <ul>
          {item.items.map((child, index) => (
            <li key={index}>
              <a href={child.permalink}>{child.title}</a>
              {this.renderChildren(child)}
            </li>
          ))}
        </ul>
      )
    }
    return
  }

  playOutroAnim() {
    const { activeSegment, sticky } = this.state

    if (activeSegment === false || (sticky && activeSegment === 'intro')) {
      this.logoRef.current.anim.playSegments([70, 87], false)
      this.setState({ activeSegment: 'outro' })
    }
  }

  toggleAnim() {
    const { activeSegment, sticky } = this.state

    if (sticky) {
      return
    }

    if (activeSegment === 'intro') {
      this.setState({ activeSegment: false })
    }

    if (activeSegment === 'outro') {
      this.logoRef.current.anim.playSegments([0, 65], false)
      this.setState({ activeSegment: 'intro' })
    }
  }

  renderMenu() {
    const { menus } = this.props
    const { menu } = menus.main
    const { activeItem } = this.state

    if (menu[activeItem]) {
      const thismenu = menu[activeItem]
      return menu[activeItem].items ? (
        <div className="container" data-id={thismenu.post_id}>
          <div className="row">
            <div className="col-lg-6">
              <div
                dangerouslySetInnerHTML={{ __html: thismenu.fields.content }}
              />
              {thismenu.fields.button ? (
                <a
                  className={
                    'btn btn-' + thismenu.fields.button_styling.toLowerCase()
                  }
                  href={thismenu.fields.button.url}
                >
                  {thismenu.fields.button.title}
                </a>
              ) : null}
            </div>
            <div className="col-lg-6">
              <MobileMenu
                menuItems={thismenu.items}
                activeMenu={' open-menu'}
                initParent={thismenu.items[0].menu_item_parent}
              />
            </div>
          </div>
        </div>
      ) : null
    }
    return
  }

  menuItemClick(id, item, e) {
    if (item.fields['menu_item_parent_lgxl']) {
      e.preventDefault()
      const { openTabletmenu } = this.state
      if (openTabletmenu) {
        this.setState({
          openTabletmenu: false,
        })
      } else {
        this.setState({
          openTabletmenu: true,
          activeItem: false,
          openMenu: false,
        })
      }

      this.props.deactivateSearch()

      return
    }

    this.setState({
      activeItem: this.state.activeItem === id || !item.items ? false : id,
      openMenu: this.state.activeItem === id || !item.items ? false : true,
      openTabletmenu: false,
    })

    this.props.deactivateSearch()
  }

  toggleSearch() {
    const { searchActive, activateSearch, deactivateSearch } = this.props
    searchActive ? deactivateSearch() : activateSearch()
    this.setState({
      openTabletmenu: false,
      activeItem: false,
      openMenu: false,
    })
  }

  render() {
    const { globals, menus, searchActive, isMobile } = this.props
    const { menu } = menus.main
    const { sticky, openMenu, activeItem, loaded } = this.state

    return (
      <header
        className={`desktop ${sticky ? 'sticky' : ''} ${
          loaded ? 'loaded' : ''
        } ${openMenu ? 'openmenu' : ''}`}
        ref={this.headerRef}
      >
        <div className={'container'}>
          <div className="menu--head">
            <div className="brand">
              <a href={globals.logo.link}>
                <Lottie
                  ref={this.logoRef}
                  options={this.defaultOptions}
                  speed={1.25}
                  isClickToPauseDisabled={true}
                  width="80px"
                  height="80px"
                />
                <div
                  className="wortmarke"
                  dangerouslySetInnerHTML={{ __html: wortmarke }}
                />
              </a>
            </div>
            <nav>
              {menu ? (
                <ul>
                  {menu.map((item, key) => (
                    <li
                      key={key}
                      className={
                        (item.post_id ? 'id-' + item.post_id : '') +
                        (item.homepage ? ' home' : '') +
                        (item.items ? ' children' : '') +
                        (item.active ? ' current' : '') +
                        (key === activeItem ? ' active' : '')
                      }
                      onClick={e => this.menuItemClick(key, item, e)}
                    >
                      <a href={item.items ? null : item.permalink}>
                        {item.title}
                      </a>
                    </li>
                  ))}
                  <li
                    className={`search ${searchActive ? 'active' : ''}`}
                    onClick={this.toggleSearch}
                  >
                    <a>Suche</a>
                  </li>
                </ul>
              ) : null}
            </nav>
          </div>
        </div>
        <div id="darkmodeSwitch" />
        <div
          className={
            'menu--body' + (openMenu || searchActive ? ' openmenu' : '')
          }
        >
          {this.renderMenu()}
          {!isMobile && <Search />}
        </div>
      </header>
    )
  }
}

DesktopHeader.propTypes = {
  globals: PropTypes.object,
  menus: PropTypes.object,
  searchActive: PropTypes.bool,
  activateSearch: PropTypes.func,
  deactivateSearch: PropTypes.func,
  isMobile: PropTypes.bool,
}

export default DesktopHeader
