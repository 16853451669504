import { connectRoutes } from 'redux-first-router'
import queryString from 'query-string'

import { FRONTPAGE, POST, AKTION_THEMEN, AKTION, SEARCH } from './routes'
import { loadPosts } from './searchPosts'
import { deactivate } from './search'

export const loadSearchPosts = (dispatch, getState) => {
  const {
    location: {
      payload: { search },
    },
  } = getState()
  dispatch(
    loadPosts({
      s: search,
    })
  )
  dispatch(deactivate())
}

const toPath = s => encodeURIComponent(s)
const fromPath = s => {
  try {
    return decodeURIComponent(s)
  } catch (e) {
    console.log(e) //eslint-disable-line
    return s
  }
}

export const routesMap = {
  [FRONTPAGE]: '/',
  [POST]: '/blog/:slug',
  [AKTION_THEMEN]: '/aktionen/:slug',
  [AKTION]: '/aktionen',
  [SEARCH]: {
    path: '/search/:search',
    thunk: loadSearchPosts,
    toPath,
    fromPath,
  },
}

const router = connectRoutes(routesMap, {
  querySerializer: queryString,
})

export const middleware = router.middleware
export const enhancer = router.enhancer

export default router.reducer
