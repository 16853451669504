import { createSelector } from 'reselect'
import Fuse from 'fuse.js'

import prop from 'ramda/es/prop'
import map from 'ramda/es/map'
import compose from 'ramda/es/compose'
import take from 'ramda/es/take'

import { sInputString } from './search'

export const sAllSearchSuggestions = state => state.searchSuggestions

const sAllSuggestions = createSelector(
  () => window.wie001_global_data.searchSuggestions,
  map(name => ({ name }))
)

const sFuse = createSelector(
  sAllSuggestions,
  terms =>
    // http://fusejs.io/
    new Fuse(terms, {
      shouldSort: true,
      tokenize: true,
      matchAllTokens: true,
      threshold: 0.2,
      location: 0,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 0,
      keys: ['name'],
    })
)

export const sSuggestionsResult = createSelector(
  sFuse,
  sInputString,
  (fuse, searchTerm) => fuse.search(searchTerm.trim())
)

export const sSuggestions = createSelector(
  sSuggestionsResult,
  compose(map(prop('name')), take(6))
)

export const sHaveSuggestions = createSelector(
  sSuggestionsResult,
  result => result.length > 0
)
