import $ from 'jquery'
import Flickity from 'flickity'

$(document).ready(function () {
  dhsv_vc_handlungsfelder()
})

function dhsv_vc_handlungsfelder() {
  $('.dhsv_vc_handlungsfelder').each(function () {
    const slider = $(this).find('section')

    new Flickity(slider[0], {
      cellAlign: 'left',
      contain: true,
      groupCells: true,
      draggable: true,
    })
  })
}
