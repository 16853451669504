import React from 'react'
import PropTypes from 'prop-types'
import MobileMenu from './../MobileMenu'
import './style.scss'
import Lottie from 'react-lottie'
import wortmarke from '../../../images/ProKlima-Wiesbaden_Wortmarke_Mobile.svg'
import * as animationData from '../../shared/animations/logoAnim.json'
import * as animationData2 from '../../shared/animations/logoAnim2.json'
import * as animationData3 from '../../shared/animations/logoAnim3.json'
import Search from '../Search'

class MobileHeader extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      searchToggled: false,
      toggled: false,
      showMenu: false,
      showSearch: false,
      sticky: false,
    }

    this.headerRef = React.createRef()
    this.logoRef = React.createRef()

    this.defaultOptions = {
      renderer: 'svg',
      loop: false,
      autoplay: false,
      animationData: props.globals.isAltHero
        ? animationData2
        : props.globals.isAktion
        ? animationData3
        : animationData,
    }

    this.handleScroll = this.handleScroll.bind(this)
    this.lockScreen = this.lockScreen.bind(this)
    this.playOutroAnim = this.playOutroAnim.bind(this)
    this.toggleAnim = this.toggleAnim.bind(this)
    this.openMenu = this.openMenu.bind(this)
    this.toggleSearch = this.toggleSearch.bind(this)
    this.searchActiveDidUpdate = this.searchActiveDidUpdate.bind(this)
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, true)
    document.addEventListener('mousedown', this.openMenuOutside)
    this.logoRef.current.anim.setSpeed(1.25)
    this.logoRef.current.anim.addEventListener('complete', this.toggleAnim)
    this.logoRef.current.el.parentNode.addEventListener(
      'mouseenter',
      this.playOutroAnim
    )
    this.logoRef.current.anim.playSegments([0, 65], false)
    this.setState({ activeSegment: 'intro' })

    window.dispatchEvent(new CustomEvent('scroll'))
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.openMenuOutside)
  }

  playOutroAnim() {
    const { activeSegment, sticky } = this.state

    if (activeSegment === false || (sticky && activeSegment === 'intro')) {
      this.logoRef.current.anim.playSegments([70, 87], false)
      this.setState({ activeSegment: 'outro' })
    }
  }

  toggleAnim() {
    const { activeSegment, sticky } = this.state

    if (sticky) {
      return
    }

    if (activeSegment === 'intro') {
      this.setState({ activeSegment: false })
    }

    if (activeSegment === 'outro') {
      this.logoRef.current.anim.playSegments([0, 65], false)
      this.setState({ activeSegment: 'intro' })
    }
  }

  handleScroll() {
    const { sticky, toggled, searchToggled } = this.state
    const distance = 30
    const scrolltop = Math.max(
      window.pageYOffset,
      document.scrollingElement ? document.scrollingElement.scrollTop : 0,
      document.documentElement ? document.documentElement.scrollTop : 0,
      document.body.scrollTop
    )

    if (scrolltop < distance) {
      this.setState({ sticky: false })
      this.toggleAnim()
    } else {
      if (!sticky && !toggled && !searchToggled) {
        this.setState({ sticky: true })
        this.playOutroAnim()
      }
    }
  }

  lockScreen(scrolltop = undefined) {
    const { isMobile } = this.props

    if (isMobile) {
      if (scrolltop === undefined) {
        document.querySelector('body').classList.remove('lock-screen')
        const topLocked =
          parseInt(document.querySelector('body > .lock-wrapper').style.top) *
          -1
        window.scroll({
          top: topLocked,
          behavior: 'smooth',
        })
        document.querySelector('body > .lock-wrapper').style.top = 'auto'
      } else {
        document.querySelector('body').classList.add('lock-screen')
        document.querySelector('body > .lock-wrapper').style.top =
          '-' + scrolltop + 'px'
      }
    }
  }

  openMenu() {
    const { toggled } = this.state
    const distance = 30
    const scrolltop = Math.max(
      window.pageYOffset,
      document.scrollingElement ? document.scrollingElement.scrollTop : 0,
      document.documentElement ? document.documentElement.scrollTop : 0,
      document.body.scrollTop
    )

    if (toggled) {
      this.lockScreen()
      this.setState({
        toggled: false,
      })
      setTimeout(
        function () {
          this.setState({
            showMenu: false,
          })
          if (scrolltop > distance) {
            this.playOutroAnim()
            this.setState({
              sticky: true,
            })
          }
        }.bind(this),
        100
      )
    } else {
      this.lockScreen(scrolltop)
      this.setState({
        toggled: true,
        showMenu: true,
        searchToggled: false,
        showSearch: false,
        sticky: false,
      })
      this.props.deactivateSearch()
      if (scrolltop < distance) {
        this.toggleAnim()
        this.setState({
          sticky: false,
        })
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { searchActive } = this.props

    if (searchActive !== prevProps.searchActive) {
      this.searchActiveDidUpdate(prevProps)
    }
  }

  searchActiveDidUpdate() {
    const { searchActive } = this.props
    const distance = 30
    const scrolltop = Math.max(
      window.pageYOffset,
      document.scrollingElement ? document.scrollingElement.scrollTop : 0,
      document.documentElement ? document.documentElement.scrollTop : 0,
      document.body.scrollTop
    )

    if (searchActive) {
      this.lockScreen(scrolltop)
      this.setState({
        showMenu: false,
        toggled: false,
        sticky: false,
      })
      if (scrolltop < distance) {
        this.toggleAnim()
        this.setState({
          sticky: false,
        })
      }
    } else {
      this.lockScreen()
      if (scrolltop > distance) {
        this.playOutroAnim()
        this.setState({
          sticky: true,
        })
      }

      setTimeout(function () {}.bind(this), 100)
    }
  }

  toggleSearch() {
    const { searchActive, activateSearch, deactivateSearch } = this.props
    searchActive ? deactivateSearch() : activateSearch()
  }

  render() {
    const { menuItems, globals, searchActive, isMobile } = this.props
    const { toggled, showMenu, sticky } = this.state
    return (
      <div
        className={`dhsv-mobile-header ${sticky ? 'sticky' : ''}`}
        ref={this.headerRef}
      >
        <div className={'header'}>
          <div className={`brand ${toggled || searchActive ? 'open' : ''}`}>
            <a href={globals.logo.link}>
              <Lottie
                ref={this.logoRef}
                options={this.defaultOptions}
                speed={1.25}
                isClickToPauseDisabled={true}
                width="38%"
                height="60px"
              />
              <div
                className="wortmarke"
                dangerouslySetInnerHTML={{ __html: wortmarke }}
              />
            </a>
          </div>
          <nav className={toggled || searchActive ? 'open' : ''}>
            <ul>
              <li
                className={'menutoggle' + (toggled ? ' open-toggle' : '')}
                onClick={this.openMenu}
              >
                Menü
              </li>
              <li
                className={
                  'searchtoggle' + (searchActive ? ' open-toggle' : '')
                }
                onClick={this.toggleSearch}
              >
                Suche
              </li>
            </ul>
          </nav>
        </div>
        {showMenu ? (
          <MobileMenu
            menuItems={menuItems}
            activeMenu={toggled ? ' open-menu' : ''}
          />
        ) : null}
        {searchActive && isMobile ? <Search /> : null}
      </div>
    )
  }
}

MobileHeader.propTypes = {
  globals: PropTypes.object,
  menuItems: PropTypes.array,
  searchActive: PropTypes.bool,
  isMobile: PropTypes.bool,
  activateSearch: PropTypes.func,
  deactivateSearch: PropTypes.func,
}

export default MobileHeader
