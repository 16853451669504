import { createSelector } from 'reselect'

export const POST = 'dhsv_theme/routes/POST'
export const FRONTPAGE = 'dhsv_theme/routes/FRONTPAGE'
export const AKTION_THEMEN = 'dhsv_theme/routes/AKTION_THEMEN'
export const AKTION = 'dhsv_theme/routes/AKTION'
export const SEARCH = 'dhsv_theme/routes/SEARCH'

export const sIsSearch = createSelector(
  state => state.location.type,
  type => [SEARCH].includes(type)
)
