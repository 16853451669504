import React from 'react'
import PropTypes from 'prop-types'
import Cookies from 'universal-cookie'

const cookies = new Cookies()

class JoinActionButton extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      clicked: false,
      cookie: cookies.get('JoinAktion')
        ? cookies
            .get('JoinAktion')
            .split(',')
            .map(item => parseInt(item))
        : [],
      error: null,
    }
    this.joinAction = this.joinAction.bind(this)
    this.updateJoin = this.updateJoin.bind(this)
  }

  joinAction(id) {
    const aktionItem = this.props.item
    const getJoin = cookies.get('JoinAktion')
    let joinAction = getJoin
      ? cookies
          .get('JoinAktion')
          .split(',')
          .map(item => parseInt(item))
      : []
    if (!joinAction.includes(id)) {
      joinAction.push(id)
      cookies.set('JoinAktion', joinAction.join(','), { path: '/' })
      cookies.set('shareLink', aktionItem.link, { path: '/' })
      cookies.set('shareTitle', aktionItem.fields.headline, { path: '/' })
      this.setState({
        clicked: !this.state.clicked,
        cookie: joinAction,
      })
    }

    this.updateJoin(id)
  }

  updateJoin(id) {
    fetch(`/wp-json/dhsv_actions/v1/join`, {
      method: 'POST',
      body: JSON.stringify({
        id: id,
      }),
    })
      .then(this.props.clicked(true))
      .catch(error => {
        this.setState({ error: error })
      })
  }

  render() {
    const { id, title } = this.props
    const { clicked, cookie } = this.state
    const ask = cookie.includes(id) || clicked
    return (
      <a
        href="#share"
        className={'mitmachen' + (ask ? ' disabled' : '')}
        onClick={!clicked ? () => this.joinAction(id) : null}
      >
        {ask ? (
          <span>
            <i className="la la-check" /> Teilgenommen
          </span>
        ) : (
          title
        )}
      </a>
    )
  }
}

JoinActionButton.propTypes = {
  isMobile: PropTypes.bool,
  item: PropTypes.object,
  id: PropTypes.number,
  title: PropTypes.string,
  clicked: PropTypes.func,
}

export default JoinActionButton
