export default {
  init() {
    const $anchorNav = $('.content-single .anchor-nav')
    $('.content-single section[data-anchor-name]').each(function () {
      const el = {
        id: $(this).attr('id'),
        name: $(this).attr('data-anchor-name'),
      }
      $anchorNav.append(`<li><a href="#${el.id}">${el.name}</a></li>`)
    })
  },
}
