import { connect } from 'react-redux'
import Component from './component'
import PropTypes from 'prop-types'
import { sIsMobile } from '../../redux/modules/browser'
import { AKTION_THEMEN, AKTION } from '../../redux/modules/routes'

const WithData = connect(
  state => ({
    isMobile: sIsMobile(state),
    themen: state.location.payload.slug,
  }),
  dispatch => ({
    changeThema: slug =>
      dispatch(
        slug === null
          ? {
              type: AKTION,
            }
          : {
              type: AKTION_THEMEN,
              payload: { slug },
            }
      ),
  })
)(Component)

WithData.propTypes = {
  isMobile: PropTypes.bool,
  themen: PropTypes.string,
}

export default WithData
