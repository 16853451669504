import { connect } from 'react-redux'
import Component from './TeaserGrid'
import PropTypes from 'prop-types'
import {
  sIsMobile,
  sIsMobileXS,
  sIsDesktopLG,
} from '../../scripts/redux/modules/browser'

const WithData = connect(state => ({
  isMobile: sIsMobile(state),
  isMobileXS: sIsMobileXS(state),
  isDesktopLG: sIsDesktopLG(state),
}))(Component)

WithData.propTypes = {
  isMobile: PropTypes.bool,
  isMobileXS: PropTypes.bool,
  isDesktopLG: PropTypes.bool,
}

export default WithData
