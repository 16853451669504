import React from 'react'
import PropTypes from 'prop-types'
import './style.scss'

class MobileMenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: 0,
      change: false,
      parentTitle: false,
      parentLink: '',
      parents: [],
      currentMenu: this.props.menuItems,
    }
    this.clickItem = this.clickItem.bind(this)
    this.clickBack = this.clickBack.bind(this)
  }

  componentDidUpdate(prevProps) {
    if (this.props.menuItems !== prevProps.menuItems) {
      this.setState({
        currentMenu: this.props.menuItems,
      })
    }
  }

  getMenu(parents = this.state.parents) {
    const { menuItems } = this.props

    if (parents.length > 0) {
      let setMenu = menuItems
      let titles = []
      let links = []
      for (let i = 0; i < parents.length; i++) {
        titles = [
          ...titles,
          setMenu.find(el => el.post_id === parents[i]).title,
        ]
        links = [
          ...links,
          setMenu.find(el => el.post_id === parents[i]).permalink,
        ]
        setMenu = setMenu.find(el => el.post_id === parents[i]).items
      }
      this.setState({
        parentTitle: titles,
        parentLink: links[links.length - 1],
        currentMenu: setMenu,
      })
    } else {
      this.setState({
        parentTitle: '',
        parentLink: '',
        currentMenu: menuItems,
      })
    }
  }

  clickItem(item) {
    if ('items' in item) {
      const newParents = [...this.state.parents, item.post_id]
      this.setState({
        change: true,
      })
      setTimeout(
        function () {
          this.setState({
            active: item.post_id,
            parents: newParents,
            change: false,
          })
          this.getMenu(newParents)
        }.bind(this),
        500
      )
    } else {
      window.location.href = item.permalink
    }
  }

  clickBack(key = 1) {
    const slice = this.state.parents.length - 1 - key
    const setParents = this.state.parents.slice(0, -slice)
    this.setState({
      change: true,
    })
    setTimeout(
      function () {
        this.setState({
          active: setParents[setParents.length - 1],
          parents: setParents,
          change: false,
        })
        this.getMenu(setParents)
      }.bind(this),
      500
    )
  }

  render() {
    const { active, parentTitle, parentLink, currentMenu, change } = this.state
    const { activeMenu, initParent } = this.props
    return (
      <div
        className={'dhsv-mobile-menu' + activeMenu + (change ? ' changed' : '')}
        data-active={active}
      >
        {currentMenu[0].menu_item_parent !== initParent ? (
          <div
            className="title"
            onClick={() => this.clickBack(parentTitle.length - 2)}
          >
            <button>
              <i className="la la-angle-left" />
            </button>
            <span>Zurück</span>
            {parentTitle
              ? parentTitle.map((title, k) => <span key={k}>{title}</span>)
              : null}
          </div>
        ) : null}
        <ul className={'menu'}>
          {currentMenu[0].menu_item_parent !== initParent && parentTitle
            ? parentTitle.map((title, k) => {
                if (k >= parentTitle.length - 1 && parentLink !== '#') {
                  return (
                    <li className="overview" key={k}>
                      <a href={parentLink}>Übersicht {'„' + title + '“'}</a>
                    </li>
                  )
                }
              })
            : null}
          {currentMenu.map((menuItem, index) => (
            <li
              key={menuItem.post_id}
              data-id={menuItem.post_id}
              className={
                (menuItem.items ? 'has-children' : '') +
                (menuItem.active ? ' current' : '') +
                (menuItem.fields['menu_item_parent_lgxl']
                  ? ' hide_on_mobile'
                  : '')
              }
              onClick={() => this.clickItem(menuItem)}
              style={{
                animationDelay: 0.1 + index * 0.05 + 's',
              }}
            >
              {menuItem.title ? menuItem.title : null}
            </li>
          ))}
        </ul>
      </div>
    )
  }
}

MobileMenu.propTypes = {
  isMobile: PropTypes.bool,
  menuItems: PropTypes.array,
  activeMenu: PropTypes.string,
  initParent: PropTypes.number,
}

MobileMenu.defaultProps = {
  initParent: 0,
}

export default MobileMenu
