import React from 'react'
import './style.scss'

class LoadingIndicator extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div className="loader-wrapper">
        <div className="loader-inner">
          <span>Beiträge werden geladen...</span>
        </div>
      </div>
    )
  }
}

export default LoadingIndicator
