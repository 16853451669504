/* global System */
import React from 'react'
import PropTypes from 'prop-types'
import './style.scss'
import Lottie from 'react-lottie'
import reducer, { loadSinglePost } from '../AktionenArchive/postState'
import FilterPointRange from '../FilterPointRange'
import * as animationData from '../../shared/animations/cloudAnim.json'
import JoinActionButton from '../AktionenArchive/joinActionButton'
import LoadingIndicator from '../LoadingIndicator/component'

class AktionenSingle extends React.Component {
  constructor(props) {
    super(props)
    this.state = reducer()
    this.clouds = React.createRef()
    this.joinState = {
      joinClicked: false,
      teilgenommen: 0,
      update: true,
      iconData: null,
    }
    this.dispatch = this.dispatch.bind(this)
    this.loadSinglePost = this.loadSinglePost.bind(this)
    this.joinClicked = this.joinClicked.bind(this)
    this.loadIcon = this.loadIcon.bind(this)
  }

  dispatch(action) {
    if (typeof action === 'function') {
      return action(this.dispatch, () => this.state)
    } else {
      this.setState(prevState => reducer(prevState, action))
    }
  }

  loadIcon(item) {
    System.import('images/icons/' + item.slug)
      .then(icon => this.setState({ iconData: icon.default }))
      .catch(() => {
        console.warn('Icon wurde nicht gefunden!!! -> ' + this.props.item.slug) //eslint-disable-line
        this.setState({ iconData: require('images/icons/default') })
      })
  }

  loadSinglePost(id) {
    return this.dispatch(loadSinglePost({ id }))
  }

  componentDidMount() {
    const id = this.props.id
    this.loadSinglePost(id).then(data => this.loadIcon(data))
  }

  componentDidUpdate() {
    const { firstPageLoaded } = this.state
    if (firstPageLoaded) {
      this.joinClicked(false)
      this.clouds.current.anim.setSpeed(0.025)
    }
  }

  joinClicked(clicked) {
    const { data: item } = this.state
    this.joinState = {
      ...this.joinState,
      teilgenommen: item.fields ? item.fields.teilgenommen : 0,
    }
    this.joinState = {
      ...this.joinState,
      teilgenommen: clicked
        ? parseInt(this.joinState.teilgenommen) + 1
        : parseInt(this.joinState.teilgenommen),
      joinClicked: clicked,
      update: this.joinState.update ? true : clicked,
    }
    if (this.joinState.update) {
      this.forceUpdate()
      this.joinState = {
        ...this.joinState,
        update: false,
      }
    }
  }

  render() {
    const { data: item, firstPageLoaded, iconData } = this.state
    const { content, prev, next } = this.props
    const defaultOptions = {
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: 'none',
      },
    }
    const join = this.joinState
    return (
      <div
        className={
          'dhsv_single-aktionen' + (this.props.isMobile ? ' mobile' : '')
        }
      >
        {firstPageLoaded ? (
          <div key={item.id} className="hero-aktion">
            <div className="row topcon">
              <div className="d-none d-lg-block col-lg-1">
                {prev ? (
                  <a
                    href={prev.permalink}
                    className="post_nav prev"
                    data-id={prev.post_title}
                    style={{ display: 'none' }}
                  >
                    <i className="la la-angle-left" />
                    <span>{prev.post_title}</span>
                  </a>
                ) : null}
              </div>
              <div className="col-12 col-lg-10">
                <div className="row titlecon">
                  <div className="col-12 col-md-10">
                    <span className="breadcrumb">
                      <a href="/aktionen">Aktionen</a>
                      <span className="delimiter">
                        {'\u00A0'}/{'\u00A0'}
                      </span>
                      {item.fields.dhsv_themen[0].slug ? (
                        <a
                          href={'/aktionen/' + item.fields.dhsv_themen[0].slug}
                          dangerouslySetInnerHTML={{
                            __html: item.fields.dhsv_themen[0].title,
                          }}
                        />
                      ) : null}{' '}
                      <span className="delimiter">
                        {'\u00A0'}/{'\u00A0'}
                      </span>
                      <span
                        className="current"
                        dangerouslySetInnerHTML={{
                          __html: item.title.rendered,
                        }}
                      />
                    </span>
                    <h1>{item.fields.headline}</h1>
                    {item.fields.subline && (
                      <h2 className="light">{item.fields.subline}</h2>
                    )}
                  </div>
                  {iconData ? (
                    <div className="col-12 col-md-2">
                      <Lottie
                        options={{
                          renderer: 'svg',
                          loop: false,
                          autoplay: true,
                          animationData: iconData,
                        }}
                        speed={1}
                        isClickToPauseDisabled={true}
                      />
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="d-none d-lg-block col-lg-1">
                {next ? (
                  <a
                    href={next.permalink}
                    className="post_nav next"
                    data-id={next.post_title}
                    style={{ display: 'none' }}
                  >
                    <i className="la la-angle-right" />
                    <span>{next.post_title}</span>
                  </a>
                ) : null}
              </div>
              <div className="cloud-anim">
                <Lottie
                  ref={this.clouds}
                  options={defaultOptions}
                  speed={0.025}
                  isClickToPauseDisabled={true}
                />
              </div>
            </div>
            <div className="row --bottomcon">
              <div className="col-12 col-lg-1" />
              <div className="col-12 col-lg-10">
                <div className="row ctn">
                  <div
                    className="col-12"
                    dangerouslySetInnerHTML={{ __html: content }}
                  />
                </div>
                <div className="row facts">
                  <div className="col">
                    <FilterPointRange
                      display={true}
                      value={parseInt(item.fields.aufwand)}
                      title="Aufwand"
                    />
                  </div>
                  <div className="col">
                    <FilterPointRange
                      display={true}
                      value={parseInt(item.fields.einfluss)}
                      title="Einfluss"
                    />
                  </div>
                  <div className="col">
                    <div
                      className={
                        'joined' + (join.joinClicked ? ' clicked' : '')
                      }
                    >
                      <div className="headline">Einwohner teilgenommen</div>
                      <div className="number">{join.teilgenommen}</div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="join-button">
                      <JoinActionButton
                        item={item}
                        id={parseInt(item.id)}
                        title="An der Aktion teilnehmen"
                        clicked={this.joinClicked}
                      />
                    </div>
                  </div>
                </div>
                {item.fields.ausgangswert.zahl &&
                item.fields.einsparung.zahl &&
                item.fields.ersparnis &&
                item.fields.gesamtersparnis ? (
                  <React.Fragment>
                    <p>
                      <strong>Zahlen & Fakten</strong>
                    </p>
                    <div
                      className={
                        'row numbers' +
                        (!item.fields.gesamtersparnis ? ' nodetail' : '')
                      }
                    >
                      <div className="col">
                        <div className="item">
                          <span>Verbrauch</span>
                          <div
                            className="number"
                            dangerouslySetInnerHTML={{
                              __html: item.fields.ausgangswert.zahl,
                            }}
                          />
                          <div
                            className="title"
                            dangerouslySetInnerHTML={{
                              __html: item.fields.ausgangswert.subline,
                            }}
                          />
                        </div>
                      </div>
                      <div className="col">
                        <div className="item">
                          <span>Ersparnispotenzial</span>
                          <div
                            className="number"
                            dangerouslySetInnerHTML={{
                              __html: item.fields.einsparung.zahl,
                            }}
                          />
                          <div
                            className="title"
                            dangerouslySetInnerHTML={{
                              __html: item.fields.einsparung.subline,
                            }}
                          />
                        </div>
                      </div>
                      <div className="col">
                        <div className="item">
                          <span>Ersparnispotenzial</span>
                          <div
                            className="number"
                            dangerouslySetInnerHTML={{
                              __html: item.fields.ersparnis,
                            }}
                          />
                          <div className="title">im direkten Vergleich</div>
                        </div>
                      </div>
                      <div className="col">
                        <div className="item">
                          <span>Gesamtersparnispotenzial</span>
                          <div className="number">
                            {item.fields.gesamtersparnis}
                          </div>
                          <div className="title">
                            bezogen auf persönlichen{' '}
                            <a href="#co2abdruck">
                              CO<sub>2</sub> Fußabdruck
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                ) : null}
              </div>
              <div className="col-12 col-lg-1" />
            </div>
          </div>
        ) : (
          <div className="hero-aktion">
            <LoadingIndicator />
          </div>
        )}
      </div>
    )
  }
}

AktionenSingle.propTypes = {
  isMobile: PropTypes.bool,
  id: PropTypes.string,
  content: PropTypes.string,
  prev: PropTypes.object,
  next: PropTypes.object,
}

export default AktionenSingle
