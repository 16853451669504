import React from 'react'
import PropTypes from 'prop-types'
import { combineReducers } from 'redux'
import Filter from './filter'
import filterReducer, { updateFilter } from './filterState'
import postsReducer, { loadPosts } from './postState'
import './style.scss'
import LoadingIndicator from '../LoadingIndicator/component'
import AktionItem from '../AktionItem'

const reducer = combineReducers({
  filter: filterReducer,
  posts: postsReducer,
})

const postsPerPage = 12

class AktionenArchive extends React.Component {
  constructor(props) {
    super(props)
    this.state = reducer()
    this.dispatch = this.dispatch.bind(this)
    this.handleChangeFilter = this.handleChangeFilter.bind(this)
    this.loadPosts = this.loadPosts.bind(this)
    this.getItems = this.getItems.bind(this)
    this.loadMore = this.loadMore.bind(this)
    this.hasMore = this.hasMore.bind(this)
  }

  dispatch(action) {
    if (typeof action === 'function') {
      action(this.dispatch, () => this.state)
    } else {
      this.setState(prevState => reducer(prevState, action))
    }
  }

  loadPosts(loadNextPage) {
    const themen = this.props.themen
    const filter = themen
      ? {
          ...this.state.filter,
          themen,
        }
      : this.state.filter
    this.dispatch(loadPosts({ postsPerPage, filter, loadNextPage }))
  }

  componentDidMount() {
    this.loadPosts()
  }

  componentDidUpdate(prevProps, prevState) {
    const filter = this.state.filter
    if (prevState.filter !== filter || prevProps.themen !== this.props.themen) {
      this.loadPosts()
    }
  }

  handleChangeFilter(name) {
    return val => {
      this.dispatch(updateFilter(name, val))
    }
  }

  loadMore() {
    this.loadPosts(true)
  }

  hasMore() {
    const { totalPages, nextPage } = this.state.posts
    return totalPages > nextPage - 1
  }

  getItems() {
    const { data } = this.state.posts
    return data.length > 0 ? (
      data.map(item => (
        <div key={item.id} className="col-md-6 col-lg-4 col-xl-3">
          <AktionItem item={item} />
        </div>
      ))
    ) : (
      <p className="noaktion">Keine Aktionen gefunden.</p>
    )
  }

  render() {
    const { customcss, isMobile, themen } = this.props
    const { isLoading, totalPosts, totalPages, nextPage } = this.state.posts
    const loadPosts =
      totalPages == nextPage
        ? totalPosts - postsPerPage * totalPages + postsPerPage
        : postsPerPage

    const filter = this.state.filter
    return (
      <div
        className={
          'dhsv_vc_aktionen_archive' + (customcss ? ' ' + customcss : '')
        }
      >
        <Filter
          onChange={this.handleChangeFilter}
          activeFilter={themen ? { thema: themen, ...filter } : filter}
          isMobile={isMobile}
          open={isMobile ? false : true}
        />
        <div className="grid">
          {this.getItems()}
          {isLoading ? <LoadingIndicator /> : null}
        </div>
        {this.hasMore() ? (
          <div className="loadmore-wrapper">
            <a
              className={`btn btn-default ${isLoading ? 'disabled' : ''}`}
              onClick={this.loadMore}
            >
              Weitere {loadPosts} von {totalPosts} Aktionen Laden
            </a>
          </div>
        ) : null}
      </div>
    )
  }
}

AktionenArchive.propTypes = {
  isMobile: PropTypes.bool,
  themen: PropTypes.string,
  customcss: PropTypes.string,
}

export default AktionenArchive
