import { createSelector } from 'reselect'
import prop from 'ramda/es/prop'
import { buildQueryString } from '../../util/string'

const initialState = {
  isLoading: false,
  firstPageLoaded: false,
  error: null,
  data: [],
  page: 0,
  totalPosts: null,
  totalPages: null,
  query: '',
}

export const postsPerPage = 12

const LOAD_POSTS = 'LOAD_SEARCH_POSTS'
const LOAD_POSTS_SUCCESS = 'LOAD_SEARCH_POSTS_SUCCESS'
const LOAD_POSTS_FAILURE = 'LOAD_SEARCH_POSTS_FAILURE'

export default (state = initialState, action = {}) => {
  const { data } = state
  const { type, payload = {} } = action

  const isFirstPage = payload.page === 1

  switch (type) {
    case LOAD_POSTS:
      return {
        ...state,
        isLoading: true,
        ...(isFirstPage
          ? {
              firstPageLoaded: false,
              totalPosts: null,
              totalPages: null,
            }
          : {}),
      }
    case LOAD_POSTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: payload.page === 1 ? payload.data : [...data, ...payload.data],
        totalPosts: parseInt(payload.headers.get('x-wp-total'), 10),
        totalPages: parseInt(payload.headers.get('x-wp-totalpages'), 10),
        page: payload.page,
        firstPageLoaded: true,
        query: payload.query,
      }
    case LOAD_POSTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload.error,
      }
    default:
      return state
  }
}

export const loadPosts = queryVars => (dispatch, getState) => {
  const state = getState()
  const {
    searchPosts: { page: lastPage, query: lastQuery },
  } = state

  const query = buildQueryString({ posts_per_page: postsPerPage, ...queryVars })

  const isContinuedQuery = lastQuery === query

  const hasNextPage = sHasNextPage(state) || !isContinuedQuery

  if (!hasNextPage) {
    return
  }

  const page = isContinuedQuery ? lastPage + 1 : 1

  dispatch({
    type: LOAD_POSTS,
    payload: { page, query },
  })

  return fetch(`/wp-json/swp_api/search?${query}&page=${page}`)
    .then(response => {
      if (response.status !== 200) {
        const message = response.statusText
          ? response.statusText
          : response.status

        throw new Error(message)
      }

      return response.json().then(json => {
        dispatch({
          payload: {
            data: json,
            headers: response.headers,
            page,
            query,
          },
          type: LOAD_POSTS_SUCCESS,
        })

        return json
      })
    })
    .catch(error => {
      console.error({ error }) //eslint-disable-line
      dispatch({
        payload: {
          error: error,
        },
        type: LOAD_POSTS_FAILURE,
      })

      throw error
    })
}

const sRoot = s => s.searchPosts

export const sTotalPosts = createSelector(sRoot, prop('totalPosts'))

export const sHasError = createSelector(sRoot, ({ error }) => !!error)

export const sHasNextPage = createSelector(
  sRoot,
  ({ page, totalPages }) => page < totalPages
)
