import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import LoadingIndicator from '../LoadingIndicator'
import Item from './Item'
import './styles.scss'
import { postShape } from '../../shared/shapes'
import { postsPerPage } from '../../redux/modules/searchPosts'
import EmptySearch from './EmpySearch'

class SearchResults extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const {
      searchTerm,
      isLoading,
      firstPageLoaded,
      totalPosts,
      page: currentPage,
      posts,
      hasNextPage,
      loadMore,
      pageTitle,
      isEmptySearchResult,
      newSearch,
    } = this.props

    const currentPosts =
      hasNextPage || !firstPageLoaded ? currentPage * postsPerPage : totalPosts

    const remainingPosts = totalPosts - currentPosts

    const morePosts =
      remainingPosts > postsPerPage ? postsPerPage : remainingPosts

    return (
      <Fragment>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        {isEmptySearchResult ? (
          <EmptySearch newSearch={newSearch} searchTerm={searchTerm} />
        ) : (
          <div className={`search-results`}>
            <div className="header">
              <div className="heading">
                Ihre Suchergebnisse für{' '}
                <span className="search-term">{searchTerm}</span>:
              </div>
            </div>
            <ul className="results">
              {posts.map(post => (
                <li key={post.id}>
                  <Item {...post} />
                </li>
              ))}
            </ul>
            <div className="footer">
              {isLoading && <LoadingIndicator />}
              {!isLoading && hasNextPage && (
                <button onClick={loadMore} className="btn btn-default">
                  Weitere {morePosts} Ergebnisse laden
                </button>
              )}
            </div>
          </div>
        )}
      </Fragment>
    )
  }
}

SearchResults.defaultProps = {
  titleTemplate: '',
}

SearchResults.propTypes = {
  searchTerm: PropTypes.string,
  isLoading: PropTypes.bool,
  firstPageLoaded: PropTypes.bool,
  hasNextPage: PropTypes.bool,
  totalPosts: PropTypes.number,
  page: PropTypes.number,
  posts: PropTypes.arrayOf(PropTypes.shape(postShape)),
  newSearch: PropTypes.func,
  loadMore: PropTypes.func,
  pageTitle: PropTypes.string,
  isEmptySearchResult: PropTypes.bool,
}

export default SearchResults
