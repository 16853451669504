import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Component from './component'
import PropTypes from 'prop-types'
import { sIsMobile } from '../../redux/modules/browser'
import { activate, deactivate } from '../../redux/modules/search'

const WithData = connect(
  state => ({
    isMobile: sIsMobile(state),
    searchActive: state.search.active,
  }),
  dispatch =>
    bindActionCreators(
      {
        activateSearch: activate,
        deactivateSearch: deactivate,
      },
      dispatch
    )
)(Component)

WithData.propTypes = {
  isMobile: PropTypes.bool,
}

export default WithData
