import { combineReducers } from 'redux'
import browser from './browser'
import router from './router'
import darkmode from './darkmode'
import search from './search'
import searchPosts from './searchPosts'
import searchKeyControl from './searchKeyControl'

const appReducer = combineReducers({
  browser,
  location: router,
  darkmode,
  search,
  searchPosts,
  searchKeyControl,
})

export default appReducer
