//import { createSelector } from 'reselect'
import { buildQueryString } from '../../util/string'

const initialState = {
  data: [],
  isLoading: false,
  firstPageLoaded: false,
  error: null,
  nextPage: 1,
  totalPosts: 0,
  totalPages: 0,
  searchTerm: '',
  loadSinglePost: false,
}

const LOAD_POSTS = 'LOAD_POSTS'
const LOAD_POSTS_SUCCESS = 'LOAD_POSTS_SUCCESS'
const LOAD_SINGLE_POST_SUCCESS = 'LOAD_SINGLE_POST_SUCCESS'
const LOAD_POSTS_FAILURE = 'LOAD_POSTS_FAILURE'

export default (state = initialState, action = {}) => {
  const { data } = state
  const { type, payload = {} } = action

  switch (type) {
    case LOAD_POSTS:
      return {
        ...state,
        isLoading: true,
      }
    case LOAD_POSTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: payload.newPage === 1 ? payload.data : [...data, ...payload.data],
        totalPosts: payload.headers.get('x-wp-total'),
        totalPages: payload.headers.get('x-wp-totalpages'),
        nextPage: payload.newPage + 1,
        firstPageLoaded: true,
        searchTerm: payload.searchTerm,
      }
    case LOAD_SINGLE_POST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        firstPageLoaded: true,
        data: payload.data,
      }
    case LOAD_POSTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload.error,
      }
    default:
      return state
  }
}

export const loadPosts = ({
  postsPerPage = 10,
  searchTerm = '',
  filter,
  postType = 'aktion',
  loadNextPage = false,
}) => (dispatch, getState) => {
  const {
    posts: { nextPage: page },
  } = getState()
  const newPage = loadNextPage ? page : 1
  let filterString = ''

  for (var k in filter) {
    filterString += k + ':' + filter[k] + ';'
  }

  const query = buildQueryString({
    per_page: postsPerPage,
    search: searchTerm,
    page: newPage,
    filter: filterString,
    order: 'asc',
    orderby: 'title',
  })

  dispatch({ type: LOAD_POSTS, payload: { newPage, postsPerPage, searchTerm } })
  //wp-json/wp/v2/aktion?filter=themen:ernaehrung;einfluss:3;aufwand:2
  return fetch(`/wp-json/wp/v2/${postType}?${query}`, {
    credentials: 'include',
  })
    .then(response => {
      if (![200, 400].includes(response.status)) {
        const message = response.statusText
          ? response.statusText
          : response.status

        throw new Error(message)
      }

      return response.json().then(json => {
        dispatch({
          payload: {
            data: json,
            headers: response.headers,
            searchTerm,
            newPage,
          },
          type: LOAD_POSTS_SUCCESS,
        })

        return json
      })
    })
    .catch(error => {
      dispatch({
        payload: {
          error: error,
        },
        type: LOAD_POSTS_FAILURE,
      })
    })
}

export const loadSinglePost = ({ id, postType = 'aktion' }) => dispatch => {
  dispatch({ type: LOAD_POSTS, payload: {} })
  return fetch(`/wp-json/wp/v2/${postType}/${id}`, { credentials: 'include' })
    .then(response => {
      if (![200, 400].includes(response.status)) {
        const message = response.statusText
          ? response.statusText
          : response.status

        throw new Error(message)
      }

      return response.json().then(json => {
        dispatch({
          payload: {
            data: json,
            headers: response.headers,
          },
          type: LOAD_SINGLE_POST_SUCCESS,
        })

        return json
      })
    })
    .catch(error => {
      dispatch({
        payload: {
          error: error,
        },
        type: LOAD_POSTS_FAILURE,
      })
    })
}

export const updatePost = ({ id }) => dispatch => {
  //wp-json/wp/v2/aktion?filter=themen:ernaehrung;einfluss:3;aufwand:2
  return fetch(
    `/wp-json/dhsv_actions/v1/join`,
    { credentials: 'include' },
    {
      method: 'POST',
      body: JSON.stringify({
        id: id,
      }),
    }
  )
    .then(
      setTimeout(() => {
        dispatch(loadSinglePost({ id }))
      }, 500)
    )
    .catch(error => {
      dispatch({
        payload: {
          error: error,
        },
        type: LOAD_POSTS_FAILURE,
      })
    })
}
