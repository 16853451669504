import React from 'react'
import PropTypes from 'prop-types'
import Flickity from 'react-flickity-component'
import sortBy from 'ramda/es/sortBy'

const shuffle = sortBy(() => Math.random() < 0.5)

class SmallBotschafterSlider extends React.Component {
  constructor(props) {
    super(props)

    this.botschafter = shuffle(props.botschafter)
  }

  componentDidMount() {
    this.flkty.on('ready', () => {
      setTimeout(() => {
        window.dispatchEvent(new CustomEvent('resize'))
      }, 1000)
    })
  }

  render() {
    const { botschafter } = this

    const flickityOptions = {
      cellSelector: '.slide',
      draggable: botschafter.length > 2 ? true : false,
      pageDots: botschafter.length > 1 ? true : false,
      prevNextButtons: false,
      contain: true,
      freeScroll: false,
      autoPlay: 5000,
      pauseAutoPlayOnHover: false,
      groupCells: 2,
    }

    return (
      <Flickity
        className={'flickity'}
        elementType={'div'}
        options={flickityOptions}
        disableImagesLoaded={false}
        reloadOnUpdate={true}
        flickityRef={c => (this.flkty = c)}
      >
        {botschafter.slice(0, 8).map((slide, i) => (
          <div className="slide" key={i}>
            <div className="img-wrapper">
              <div dangerouslySetInnerHTML={{ __html: slide.image }} />
            </div>
          </div>
        ))}
      </Flickity>
    )
  }
}

SmallBotschafterSlider.propTypes = {
  botschafter: PropTypes.array.isRequired,
}

export default SmallBotschafterSlider
