import React from 'react'
import PropTypes from 'prop-types'
import AktionItem from '../../scripts/components/AktionItem/component'
import TeaserBoxTestimonial from '../../scripts/components/TeaserBoxTestimonial/component'
import TeaserBox from '../../scripts/components/TeaserBox/component'
import Filter from './filter'
import groupBy from 'ramda/es/groupBy'
import prop from 'ramda/es/prop'
import Flickity from 'react-flickity-component'

function fyshuffle(a, b, c, d) {
  c = a.length
  while (c)
    (b = (Math.random() * (--c + 1)) | 0), (d = a[c]), (a[c] = a[b]), (a[b] = d)
}

const url = new URL(window.location.href)

class TeaserGrid extends React.Component {
  constructor(props) {
    super(props)
    if (props.options.randomsort) {
      this.randomData = props.data
      fyshuffle(this.randomData)
      if (props.options.postlimit) {
        this.randomData = this.randomData.slice(0, props.options.postlimit)
      }
    }
    this.state = {
      filtering: url.searchParams.get('tag'),
      showFilter: this.props.options.showfilter.use,
      showLoadMore: this.props.options.loadmore.use,
      displayItems: parseFloat(this.props.options.loadmore.options.show),
      loadItems: parseFloat(this.props.options.loadmore.options.load),
    }
    this.getBox = this.getBox.bind(this)
    this.getItemCount = this.getItemCount.bind(this)
    this.fillIfExternal = this.fillIfExternal.bind(this)
    this.loadMore = this.loadMore.bind(this)
    this.handleChangeFilter = this.handleChangeFilter.bind(this)
    this.renderItems = this.renderItems.bind(this)
    this.renderBtn = this.renderBtn.bind(this)
    this.resizeFlickity = this.resizeFlickity.bind(this)
  }

  componentDidMount() {
    this.fillIfExternal()
    if (this.props.options.earlyslider) {
      window.addEventListener('resize', this.resizeFlickity)
    }
  }

  getBox(item) {
    switch (item.posttype) {
      case 'aktion':
        return <AktionItem item={item} />
      case 'testimonial':
        return <TeaserBoxTestimonial item={item} />
      default:
        return <TeaserBox item={item} />
    }
  }

  getItemCount(posttype = 'post') {
    return this.props.data.filter(item => posttype.includes(item.posttype))
      .length
  }

  fillIfExternal() {
    const { displayItems, showLoadMore } = this.state
    if (this.getItemCount(['external']) > 0 && showLoadMore) {
      const countPages = this.getItemCount(['page', 'post', 'aktion'])
      this.setState({
        displayItems: displayItems + countPages,
      })
    }
  }

  loadMore() {
    const { loadItems, displayItems } = this.state
    this.setState({
      displayItems: displayItems + loadItems,
    })
  }

  handleChangeFilter(name) {
    const newName = name === this.state.filtering ? '' : name
    history.pushState(newName, newName, '?tag=' + newName)
    this.setState({
      filtering: newName,
    })
    window.dispatchEvent(new CustomEvent('resize'))
  }

  renderItems(data) {
    const { options, cols, typesort, isDesktopLG, isMobileXS } = this.props

    if (
      (!options.noslider && isMobileXS) ||
      (options.earlyslider && isDesktopLG)
    ) {
      const groupedData = groupBy(prop('posttype'))(data)

      return typesort.map((group, index) => (
        <Flickity
          key={index}
          className={'slider'}
          elementType={'div'}
          options={{
            cellSelector: '.slide',
            draggable: group.length > 1 ? true : false,
            pageDots: group.length > 1 ? true : false,
            prevNextButtons: false,
            contain: true,
            freeScroll: false,
            autoPlay: false,
            groupCells: true,
          }}
          disableImagesLoaded={false}
          flickityRef={c => (this.flkty = c)}
        >
          {groupedData[group]
            ? groupedData[group].map((slide, i) => (
                <div className={`slide ${slide.posttype}`} key={i}>
                  {this.getBox(slide)}
                </div>
              ))
            : null}
        </Flickity>
      ))
    } else {
      return data.map((item, key) => (
        <React.Fragment key={key}>
          {item.posttype === 'external' ? <div className="line-break" /> : null}
          <div
            className={
              (isMobileXS && options.noslider && options.twoinrow
                ? 'col-6'
                : cols) +
              ' ' +
              item.posttype
            }
          >
            {this.getBox(item)}
          </div>
        </React.Fragment>
      ))
    }
  }

  renderLoadmore(show) {
    const { data, options, isMobileXS } = this.props
    const { mobilelink } = options.loadmore.options
    const { showLoadMore, displayItems } = this.state

    if (!showLoadMore || !show) return null

    if (mobilelink && isMobileXS) {
      return (
        <div
          className={
            'buttonwrapper load' +
            (displayItems < data.length ? '' : ' disabled')
          }
        >
          <a href={mobilelink.url} className={'btn btn-default'}>
            {mobilelink.title}
          </a>
        </div>
      )
    } else {
      return (
        <div
          className={
            'buttonwrapper load' +
            (displayItems < data.length ? '' : ' disabled')
          }
        >
          <button className={'btn btn-default'} onClick={this.loadMore}>
            Mehr Laden
          </button>
        </div>
      )
    }
  }

  renderBtn() {
    const { button } = this.props

    if (button.url) {
      return (
        <div className="buttonwrapper">
          <a
            href={button.url}
            className={'btn' + (button.style ? ' ' + button.style : '')}
            target={button.target}
            rel={button.rel}
          >
            {button.title}
          </a>
        </div>
      )
    }
  }

  resizeFlickity() {
    const points = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl']
    const breakpoint = window.breakpoint
    const slider = this.flkty
    if (slider) {
      slider.cells.forEach(function (cell) {
        const count = slider.cells.length
        points.forEach(function (v, i) {
          if (breakpoint === v) {
            const width = count > i ? 100 / i : 100 / count
            cell.element.style.width = width + '%'
          }
        })
      })
    }
  }

  render() {
    const { options, data, isMobile } = this.props
    const { showFilter, showLoadMore, filtering, displayItems } = this.state
    const choosedData = options.randomsort ? this.randomData : data
    let filter = choosedData
      .map(item => {
        return item.fields.tags ? item.fields.tags.join(',') : ''
      })
      .filter((v, i, a) => a.indexOf(v) === i)
    filter = filter.join(',')
    filter = filter.split(',').filter(function (v, i, s) {
      return s.indexOf(v) === i
    })
    const arrLength = choosedData.filter(item =>
      filtering ? item.fields.tags.includes(filtering) : true
    ).length
    const newData = showLoadMore
      ? choosedData
          .filter(item =>
            filtering ? item.fields.tags.includes(filtering) : true
          )
          .slice(0, displayItems)
      : choosedData.filter(item =>
          filtering ? item.fields.tags.includes(filtering) : true
        )
    return data ? (
      <div className="dhsv_vc_teasergrid">
        {showFilter ? (
          <Filter
            onChange={this.handleChangeFilter}
            items={filter}
            activeFilter={filtering}
            isMobile={isMobile}
            open={isMobile ? false : true}
          />
        ) : null}
        <div className="row">{this.renderItems(newData)}</div>
        {this.renderLoadmore(displayItems < arrLength)}
        {this.renderBtn()}
      </div>
    ) : null
  }
}

TeaserGrid.propTypes = {
  cols: PropTypes.string,
  data: PropTypes.array,
  typesort: PropTypes.array,
  button: PropTypes.object,
  options: PropTypes.object,
  isMobile: PropTypes.bool,
  isMobileXS: PropTypes.bool,
  isDesktopLG: PropTypes.bool,
}

export default TeaserGrid
