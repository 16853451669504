import React from 'react'
import PropTypes from 'prop-types'
import { PanelGroup, Panel } from 'react-bootstrap'

class ContentAccordion extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      startKey: '99',
    }
  }

  render() {
    return (
      <PanelGroup
        accordion
        id="accordion-controlled"
        defaultActiveKey={this.state.startKey}
      >
        {this.props.items.map((item, i) => (
          <Panel eventKey={i} key={i}>
            <Panel.Heading>
              <Panel.Title toggle>
                <div dangerouslySetInnerHTML={{ __html: item['title'] }} />
              </Panel.Title>
            </Panel.Heading>
            <Panel.Body collapsible>
              <div dangerouslySetInnerHTML={{ __html: item['content'] }} />
            </Panel.Body>
          </Panel>
        ))}
      </PanelGroup>
    )
  }
}

ContentAccordion.propTypes = {
  items: PropTypes.array,
}

export default ContentAccordion
