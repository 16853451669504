import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Component from './component'

import { deactivate } from '../../redux/modules/search'
import {
  updateSelection,
  activateCurrentSelection,
  sIsSuggestionSelected,
} from '../../redux/modules/searchKeyControl'

export default connect(
  state => ({
    active: state.search.active,
    isSuggestionSelected: sIsSuggestionSelected(state),
  }),
  dispatch =>
    bindActionCreators(
      { deactivate, updateSelection, activateCurrentSelection },
      dispatch
    )
)(Component)
