import $ from 'jquery'

$(document).ready(dhsv_vc_modal_image_click)
$(window).load(dhsv_vc_modal_image_size)
$('body').scroll(dhsv_vc_modal_image_size)
$(window).resize(dhsv_vc_modal_image_size)

document.addEventListener('mousemove', dhsv_vc_modal_image_mouse)

function dhsv_vc_modal_image_mouse(e) {
  $('.dhsv_vc_modal_image').each(function () {
    const modal = $(this).find('.modalimage')
    if (modal.hasClass('open')) {
      $(this).find('.mouse').css({
        top: e.clientY,
        left: e.clientX,
      })
    }
  })
}

function dhsv_vc_modal_image_size() {
  $('.dhsv_vc_modal_image').each(function () {
    const modal = $(this).find('.modalimage')
    const toggle = $(this).find('.toggleimage')

    modal.removeClass('open')

    modal.find('.inner').css({
      width: toggle.width(),
      height: toggle.height(),
      top: toggle[0].getBoundingClientRect().top,
      left: toggle[0].getBoundingClientRect().left,
    })
  })
}

function dhsv_vc_modal_image_click() {
  $('.dhsv_vc_modal_image').each(function () {
    const modal = $(this).find('.modalimage')
    const toggle = $(this).find('.toggleimage')

    toggle.click(function () {
      const ratio = toggle.height() / toggle.width()
      const ratioH = toggle.width() / toggle.height()
      const winHeight = $(window).height()
      const winWidth = $(window).width()
      let width = winWidth
      let height = width * ratio

      if (height > winHeight) {
        height = winHeight
        width = height * ratioH
      }

      modal.addClass('open')

      modal.find('.inner').css({
        width: width,
        height: height,
        top: winHeight / 2 - (width * ratio) / 2,
        left: winWidth / 2 - width / 2,
      })
    })

    modal.click(dhsv_vc_modal_image_size)
  })
}
