import React from 'react'
import PropTypes from 'prop-types'

const EmptySearch = ({ newSearch, searchTerm }) => (
  <div className="search-results empty">
    <div className="header">
      <div className="heading">
        <p>
          Ihre Suche nach <span className="search-term">{searchTerm}</span>{' '}
          ergab leider keine Treffer.
        </p>
        <button className="btn btn-primary" onClick={newSearch}>
          <i className="la la-rotate-right" /> neue Suche
        </button>
      </div>
    </div>
  </div>
)

EmptySearch.propTypes = {
  newSearch: PropTypes.func.isRequired,
  searchTerm: PropTypes.string.isRequired,
}

export default EmptySearch
