import React from 'react'
import PropTypes from 'prop-types'
import './style.scss'

class FilterPointRange extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hoverPoint: 0,
      activePoint: this.props.value,
      clickedPoint: false,
      hover: false,
    }
    this.MouseEnter = this.MouseEnter.bind(this)
    this.MouseLeave = this.MouseLeave.bind(this)
    this.MouseClick = this.MouseClick.bind(this)
    this.createPoints = this.createPoints.bind(this)
  }

  MouseEnter(point) {
    this.setState({
      hoverPoint: point,
      hover: true,
    })
  }

  MouseLeave() {
    this.setState({
      hoverPoint: 0,
      hover: false,
    })
  }

  MouseClick(point) {
    const active = this.state.activePoint === point ? null : point
    this.setState({
      hoverPoint: point,
      activePoint: active,
      clickedPoint: true,
    })
    this.props.onChange(active)
  }

  createPoints() {
    const { hoverPoint, activePoint } = this.state
    const { count, display } = this.props
    let points = []

    for (let i = 1; i <= count; i++) {
      points.push(
        <span
          className={
            (hoverPoint >= i ? 'hover' : '') +
            ' ' +
            (activePoint >= i ? 'active' : '')
          }
          key={i}
          onMouseEnter={!display ? () => this.MouseEnter(i) : null}
          onMouseLeave={!display ? () => this.MouseLeave() : null}
          onClick={!display ? () => this.MouseClick(i) : null}
        />
      )
    }
    return points
  }

  render() {
    const { hover } = this.state
    const { display, title } = this.props

    return (
      <div className={'dhsv_filter_point_range' + (display ? ' display' : '')}>
        <div className="headline">{title}</div>
        <div className={'points' + (hover ? ' hover' : '')}>
          {this.createPoints()}
        </div>
      </div>
    )
  }
}

FilterPointRange.propTypes = {
  isMobile: PropTypes.bool,
  count: PropTypes.number,
  display: PropTypes.bool,
  value: PropTypes.number,
  title: PropTypes.string,
  onChange: PropTypes.func,
}

FilterPointRange.defaultProps = {
  display: false,
  count: 5,
  value: 0,
}

export default FilterPointRange
