/* global System */
import React from 'react'
import PropTypes from 'prop-types'
import './style.scss'
import Lottie from 'react-lottie'

class TeaserBox extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      joinClicked: false,
      joined: false,
      teilgenommen: this.props.item.fields.teilgenommen
        ? this.props.item.fields.teilgenommen
        : 0,
      iconData: null,
    }
    this.getPosttypeName = this.getPosttypeName.bind(this)
    this.loadIcon = this.loadIcon.bind(this)
  }

  getPosttypeName(slug) {
    const translate = {
      post: 'Aktuelles',
      aktion: 'Aktion',
      external: 'Externer Link',
      klimathema: 'Thema',
      testimonial: 'Testimonial',
      presse: 'Pressemittelungen',
    }

    return translate[slug]
  }

  componentDidMount() {
    if (!this.props.item.image) {
      this.loadIcon()
    }
  }

  loadIcon() {
    System.import('images/icons/' + this.props.item.slug)
      .then(icon => this.setState({ iconData: icon.default }))
      .catch(() => {
        console.warn('Icon wurde nicht gefunden!!! -> ' + this.props.item.slug) //eslint-disable-line
        this.setState({ iconData: require('images/icons/default') })
      })
  }

  render() {
    const { item } = this.props
    const { iconData } = this.state

    let link = item.link
    if (['external'].includes(item.posttype)) {
      link = item.fields.url
    } else if (['presse'].includes(item.posttype)) {
      link = item.file ? item.file.url : item.link
    } else {
      link = item.link
    }
    return (
      <div
        className={
          'dhsv_teaserbox' + (item.posttype ? ' ' + item.posttype : '')
        }
        data-id={item.id}
      >
        <div className="inner hover">
          <span className="index">
            {item.thema ? <b>{item.thema[0].title}</b> : null}
            {item.tags ? ' / ' + item.tags[0] : ''}
          </span>
          <h3
            dangerouslySetInnerHTML={{
              __html: item.name,
            }}
          />
          <p
            dangerouslySetInnerHTML={{
              __html: ['external'].includes(item.posttype)
                ? item.fields.text
                : item.description,
            }}
          />
          <a
            href={link}
            target={
              ['external', 'presse'].includes(item.posttype) ? '_blank' : null
            }
            download={item.file ? 'download' : null}
          >
            mehr erfahren
          </a>
        </div>
        <div className="inner">
          <span className="index">
            {item.thema ? (
              <div>
                <b>{item.thema[0].title}</b>
                {item.tags
                  ? item.thema[0].title !== item.tags[0]
                    ? ' / ' + item.tags[0]
                    : ''
                  : ''}
              </div>
            ) : item.tags ? (
              item.tags[0]
            ) : null}
          </span>
          <h3
            dangerouslySetInnerHTML={{
              __html: item.name,
            }}
          />
          {/*{item.tags ? (
            <ul>{item.tags.map((tag, k) => <li key={k}>{tag}</li>)}</ul>
          ) : null}*/}
          {item.image ? (
            <div className="imagewrapper lazyload" data-bg={item.image} />
          ) : (
            <div className="iconwrapper">
              {!['external', 'presse'].includes(item.posttype) && iconData ? (
                <Lottie
                  options={{
                    renderer: 'svg',
                    loop: false,
                    autoplay: true,
                    animationData: iconData,
                  }}
                  speed={1}
                  isClickToPauseDisabled={true}
                />
              ) : null}
            </div>
          )}
        </div>
      </div>
    )
  }
}

TeaserBox.propTypes = {
  item: PropTypes.object,
}

export default TeaserBox
