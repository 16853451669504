import React from 'react'
import PropTypes from 'prop-types'
import './style.scss'
import SearchKeyControl from '../SeachKeyControl'
import SearchSuggestions from '../SearchSuggestions'

class Search extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      toggled: false,
    }
    this.handleClick = this.handleClick.bind(this)
    this.focusInput = this.focusInput.bind(this)
  }

  handleClick() {
    this.setState({
      toggled: !this.state.toggled,
    })
  }

  componentDidMount() {
    const { active } = this.props

    if (active) {
      this.focusInput()
    }
  }

  componentDidUpdate(prevProps) {
    const { active: wasActive } = prevProps
    const { active } = this.props

    if (wasActive && !active) {
      this.searchInput.blur()
    }

    if (!wasActive && active) {
      this.focusInput()
    }
  }

  focusInput() {
    setTimeout(() => this && this.searchInput && this.searchInput.focus(), 1000)
  }

  render() {
    const {
      gotoSearch,
      inputString,
      updateInput,
      active,
      isMobile,
    } = this.props

    const mobileClass = isMobile ? 'mobile' : ''
    const openClass = active ? 'open-search' : ''
    const label = inputString
      ? 'Meinten Sie...'
      : isMobile
      ? 'Suchbegriff'
      : 'Bitte geben Sie Ihren Suchbegriff hier ein'

    const hasInput = !!inputString

    return (
      <div className={`dhsv_search ${mobileClass} ${openClass}`}>
        <SearchKeyControl>
          <div className="inner container">
            <div className="searchfield">
              <form
                action="/"
                method="get"
                onSubmit={e => {
                  e.preventDefault()
                  inputString && gotoSearch(inputString)
                }}
              >
                <input
                  type="text"
                  name="s"
                  className={this.state.toggled ? 'toggled' : ''}
                  value={inputString}
                  autoComplete="off"
                  onChange={({ currentTarget: { value } }) =>
                    updateInput(value)
                  }
                  ref={node => (this.searchInput = node)}
                />
                <label>{label}</label>
                <button type="submit">Suchen</button>
                <i className="la la-search" />
                {hasInput ? (
                  <i className="la la-close" onClick={() => updateInput('')} />
                ) : null}
              </form>
            </div>
          </div>
          <SearchSuggestions />
        </SearchKeyControl>
      </div>
    )
  }
}

Search.propTypes = {
  isMobile: PropTypes.bool,
  inputString: PropTypes.string,
  gotoSearch: PropTypes.func,
  updateInput: PropTypes.func,
  active: PropTypes.bool,
}

export default Search
